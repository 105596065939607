import server from '../../api/server';

export class FormatMoulding {
  static async mouldingReportData(token: string, userId: number, consultId: number): Promise<any> {

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    };

    var imcDataArray : any = [];
    var bodyCompositionDataArrayPercent : any = [];
    var bodyCompositionDataArrayKg : any = [];
    var extremitiesCompositionDataMuscleArrayFat : any = [];
    var extremitiesCompositionDataMuscleArrayMuscle : any = [];
    var methbolicRiskArray : any = [];
    var somatypeDataArray : any = [];

    try {
      const responseServer = await server.post(
        `staff/nutrition-consult/moulding/${userId}/${consultId}`, "", {headers}
      );

      const response = responseServer.data.data;

      imcDataArray.push(parseFloat(response.indices.imc.toFixed(1)));

      bodyCompositionDataArrayPercent.push(parseFloat(response.bodyComposition.musclePercent.toFixed(1)));
      bodyCompositionDataArrayPercent.push(parseFloat(response.bodyComposition.fatPercent.toFixed(1)));
      bodyCompositionDataArrayPercent.push(parseFloat(response.bodyComposition.bonePercent.toFixed(1)));
      bodyCompositionDataArrayPercent.push(parseFloat(response.bodyComposition.residualPercent.toFixed(1)));
      bodyCompositionDataArrayKg.push(parseFloat(response.bodyComposition.muscle.toFixed(1)));
      bodyCompositionDataArrayKg.push(parseFloat(response.bodyComposition.fat.toFixed(1)));
      bodyCompositionDataArrayKg.push(parseFloat(response.bodyComposition.bone.toFixed(1)));
      bodyCompositionDataArrayKg.push(parseFloat(response.bodyComposition.residual.toFixed(1)));

      var armfat = parseFloat((response.arm.arm_AG / response.arm.arm_AT * 100).toFixed(1));
      var thighfat = parseFloat((response.thigh.thigh_AG / response.thigh.thigh_AT * 100).toFixed(1));
 
      extremitiesCompositionDataMuscleArrayFat.push(armfat);
      extremitiesCompositionDataMuscleArrayFat.push(thighfat);
      extremitiesCompositionDataMuscleArrayFat.push(thighfat);

      var armmuscle = parseFloat((response.arm.arm_AM / response.arm.arm_AT * 100).toFixed(1));
      var thighmuscle = parseFloat((response.thigh.thigh_AM / response.thigh.thigh_AT * 100).toFixed(1));
      var calfmuscle = parseFloat((response.calf.calf_AM / response.calf.calf_AT * 100).toFixed(1));
      extremitiesCompositionDataMuscleArrayMuscle.push(armmuscle);
      extremitiesCompositionDataMuscleArrayMuscle.push(thighmuscle);
      extremitiesCompositionDataMuscleArrayMuscle.push(calfmuscle);

      methbolicRiskArray.push(parseFloat(response.circunferences.waist.toFixed(1)));
      methbolicRiskArray.push(parseFloat(response.indices.ice.toFixed(2)));
      methbolicRiskArray.push(parseFloat(response.indices.icc.toFixed(2)));

      somatypeDataArray.push(parseFloat(response.somatype.endomorph.toFixed(1)));
      somatypeDataArray.push(parseFloat(response.somatype.mesomorph.toFixed(1)));
      somatypeDataArray.push(parseFloat(response.somatype.ectomorph.toFixed(1)));

      return {
        info: responseServer.data.data.information,
        imc: imcDataArray,
        bodyPercent: bodyCompositionDataArrayPercent,
        bodyKg: bodyCompositionDataArrayKg,
        extFat: extremitiesCompositionDataMuscleArrayFat,
        extMuscle: extremitiesCompositionDataMuscleArrayMuscle,
        metRisk: methbolicRiskArray,
        soma: somatypeDataArray,
        coor: [
          parseFloat(response.somatype.somatotypE_X),
          parseFloat(response.somatype.somatotypE_Y)
        ]
      };
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  }
}

export const imcOptions = {
  indexAxis: 'y' as const,
  scales: {
    x: {
      beginAtZero: true,
      suggestedMin: 0,
      suggestedMax: 30,
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      display: false
    }
  },
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black' as const,
      formatter: function(value: any, context: any) {
        var text = "";
        if(value < 18.5) {
          text = "Bajo peso";
        } else if(value <= 24.9) {
          text = "Normal";
        } else if(value <= 29.9) {
          text = "Sobrepeso";
        } else {
          text = "Obesidad";
        }
        return `${value} - ${text}`;
      }
    },
  },
};

export const composicionCorporalOptions = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black' as const,
      formatter: function(value: any, context: any) {
        return `${context.chart.data.labels[context.dataIndex]}: ${value}%`;
      }
    }
  },
};

export const composicionExtremidadesOptions = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom' as const,
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black' as const,
    }
  },
};

export const somatotipoOptions = {
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      color: 'black' as const,
    }
  },
};
