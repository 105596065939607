import { useState, useContext, useEffect } from 'react';
import { Container, Grid, Button, CardContent, CardActions } from "@mui/material";
import { MyCard, MyTitle, MyLoading, MyButton, MyDialog } from '../../components/Generic/Index';
import { AppContext } from "../../data/state";
import { useLocation, useNavigate } from "react-router-dom";
import { GetConsultDocumentTypeService } from "../../services/Nutrition/ConsultDocumentType";
import { CreateUserConsultDocumentService } from "../../services/Nutrition/CreateUserConsultDocument";
import { CompleteSessionService } from "../../services/Nutrition/CompleteSession";
import { UserInfoService } from "../../services/User";
import { navigateWithScroll } from "../../functions/navigateUtils";

var documents = new Map();

const FileUpload: React.FC = () => {
  const { state } = useContext(AppContext);
  const [documentsTypes, setDocumentsTypes] = useState<any[]>([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogMessageText, setDialogMessageText] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const [sessionNum, setSessionNum] = useState(0);
  const [nutritionConsultID, setNutritionConsultID] = useState(0);

  const location: any = useLocation();
  const navigate = useNavigate();

  const token = state.token;

  useEffect(() => {
    if (location.state && location.state.consultId) {
      setNutritionConsultID(location.state.consultId);
      setUserId(location.state.userId);
      requestUserInfo(location.state.userId);
    }

    requestDocumentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestUserInfo = async (id: any) => {
    setIsActive(true);
    try {
      const userInfo = await UserInfoService.getUser(token, id);

      setUserName(userInfo.name);
      setUserId(userInfo.id);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const requestDocumentType = async () => {
    setIsActive(true);
    try {
      const documentTypes = await GetConsultDocumentTypeService.getDocumentTypes(token);

      documentTypes.forEach((element: any) => {
        if (element.active === true) {
          documents.set(element.documentType, {
            id: element.id,
            document: "",
            name: ""
          });
        }
      });

      setDocumentsTypes(documentTypes);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangeDocument = async (e: any, documentType: string) => {
    setIsActive(true);
    let file = e.target.files[0];
    let prefix = "data:application/pdf;base64,";

    if (file) {
      const binaryString = await convertToBase64(file);
      const binaryStr = binaryString as string;

      await documents.set(documentType, {
        ...documents.get(documentType),
        document: binaryStr.substring(prefix.length),
        name: file.name
      });
    }

    setIsActive(false);
  };

  const submitDocuments = async () => {
    setIsActive(true);
    Array.from(documents.entries()).map(([key, val]) => {
      if (val.document !== "") {
        const data = {
          nutritionConsultID: nutritionConsultID,
          nutritionDocumentTypeID: val.id,
          document: val.document
        };

        (async () => {
          try {
            const userDocuments = await CreateUserConsultDocumentService.createConsultDocument(token, data);
          } catch (error: any) {
            setIsActive(false);
            console.log(error);
            return;
          }
        })();
      }
    })

    //FIXME
    setIsDialogOpen(true);
    setDialogTitleText("Documentos guardados con exito!");
    setDialogMessageText("Desea completar la sesion?")
    setIsActive(false);
  };

  const completeUserSession = async () => {
    setIsActive(true);
    try {
      const completeSession = await CompleteSessionService.completeSession(token, nutritionConsultID);
      navigateWithScroll(navigate, '/nutrition');
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }

  };

  const save = () => {
    submitDocuments();
  };

  const cancelDialoghandle = () => {
    setIsDialogOpen(false);
  };

  const confirmDialoghandle = () => {
    setIsDialogOpen(false);
    completeUserSession();
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>

        <MyCard>
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">

            <Grid item xs={12}>
              <MyTitle
                title={userName ? userName : 'Nombre del socio'}
                variant="h5"
                align="left"
                color="primary"
              />
              <MyTitle
                title={"Número de socio: " + userId}
                variant="subtitle2"
                align="left"
                color="dark"
                gutterBottom
                noWrap
              />
            </Grid>
          </Grid>

          <CardContent>
            <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">

              {documentsTypes.map((item, index) => {
                return (
                  <div key={index}>
                    <Grid item xs={12}>
                      <MyTitle
                        title={item.documentType}
                        variant='subtitle1'
                        align='center'
                        color='dark'
                      />
                    </Grid>

                    {documents.get(item.documentType) && documents.get(item.documentType).name !== "" ?
                      <Grid item xs={12}>
                        <MyTitle
                          title={`${documents.get(item.documentType).name}`}
                          variant='body2'
                          align='center'
                          color='dark'
                        />
                      </Grid>
                      : <Grid item xs={12}>
                        <MyTitle
                          title={"Nombre de archivo"}
                          variant='body2'
                          align='center'
                          color='light'
                        />
                      </Grid>
                    }

                    <Grid item xs={12} style={{ textAlign: 'center', margin: '0 auto', marginTop: '1vh' }}>
                      <Button
                        variant="outlined"
                        component="label"
                        size='small'
                      >
                        Cargar
                        <input
                          hidden
                          accept="application/pdf"
                          type="file"
                          onChange={(e) => onChangeDocument(e, item.documentType)}
                          key={`${item.documentType}-input`}
                        />
                      </Button>
                    </Grid>
                  </div>
                );
              })
              }
            </Grid>
          </CardContent>
        </MyCard>
        <CardActions sx={{ justifyContent: "flex-end" }} style={{ paddingRight: '0px' }}>
          <MyButton
            title="Subir archivos"
            color="primary"
            handleClick={save}
          />
        </CardActions>
      </Container>
      <MyDialog
        open={isDialogOpen}
        title={dialogTitleText}
        message={dialogMessageText}
        cancelTextButton="Volver a historial"
        confirmTextButton="Aceptar"
        cancelHandle={cancelDialoghandle}
        confirmHandle={confirmDialoghandle}
      />
    </>
  );
};

export default FileUpload;
