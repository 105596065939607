import server from '../../api/server';

export class GetNutritionMETService {
  static async getActivities(token: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    };

    try {
      const responseServer = await server.get(
        `nutrition-met`,
        { headers }
      );
      return responseServer.data.data;
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  }
}
