import React, { useState, useRef, useEffect, useContext } from "react";
import { MyCard, MyTitle, MyTextField, MyLoading, MyItemContainer, MyItem } from '../../components/Generic/Index';
import { Grid, CardContent, Typography, Container, TableRow, TableCell, TableBody, TableContainer, Table, TableHead } from "@mui/material";
import { AppContext } from "../../data/state";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import './NutritionReport.css';
import { curveBundle, axisLeft, axisBottom, select, scaleLinear, line } from 'd3';
import {
  FormatMoulding, composicionCorporalOptions, composicionExtremidadesOptions,
  somatotipoOptions, imcOptions
} from "./ReportData";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NutritionReport: React.FC = () => {
  const theme = useTheme();
  const svgRef = useRef(null);
  const navigate = useNavigate();
  const location: any = useLocation();

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [consultId, setConsultId] = useState(0);
  const [userEvaluationDate, setUserEvaluationDate] = useState<string>("");
  const [userNumberSession, setUserNumberSession] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userHeight, setUserHeight] = useState("");
  const [userWeigth, setUserWeigth] = useState("");
  const [userGender, setUserGender] = useState("");

  const [imc, setImc] = useState({
    labels: ["IMC"],
    datasets: [
      {
        label: 'Indice',
        data: [0],
        barPercentage: 1,
        borderWidth: 1,
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
      },
    ],
  });
  const [bodyPercent, setBodyPercent] = useState({
    labels: ['Musculatura', 'Grasa', 'Hueso', 'Residual'],
    datasets: [
      {
        label: 'Composicion corporal',
        data: [0],
        backgroundColor: [
          'rgba(255, 99, 132, 0.9)',
          'rgba(54, 162, 235, 0.9)',
          'rgba(255, 206, 86, 0.9)',
          'rgba(75, 192, 192, 0.9)',
          'rgba(153, 102, 255, 0.9)',
          'rgba(255, 159, 64, 0.9)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [extremitiesComposition, setExtremitiesComposition] = useState({
    labels: ['Pantorrilla', 'Muslo', 'Brazo'],
    datasets: [
      {
        label: '% Musculatura',
        data: [0],
        borderColor: 'lightBlue',
        backgroundColor: 'rlightBlue',
      },
      {
        label: '% Grasa',
        data: [0],
        borderColor: 'lightGreen',
        backgroundColor: 'lightGreen',
      },
    ],
  });

  const [somatype, setSomatype] = useState({
    labels: ['Endomorfo', 'Mesomorfo', 'Ectomorfo'],
    datasets: [
      {
        data: [0],
        borderColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
        backgroundColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
      },
    ],
  });
  const [bodyKg, setBodyKg] = useState([]);
  const [bodyCompositionPercent, setBodyCompositionPercent] = useState([]);
  const [extremitiesCompositionMuscle, setExtremitiesCompositionMuscle] = useState([]);
  const [extremitiesCompositionFat, setExtremitiesCompositionFat] = useState([]);
  const [metabolicRisk, setMetabolicRisk] = useState([]);
  const [coordinates, setCoordinates] = useState([0, 0]);

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  const { state } = useContext(AppContext);
  const [isActive, setIsActive] = useState(false);
  const token = state.token;

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId);
      setConsultId(location.state.consultId);
      requestData(location.state.userId, location.state.consultId);
    }
  }, []);

  const requestData = async (userId: any, consultId: any) => {
    setIsActive(true);

    try {
      const mouldingResult = await FormatMoulding.mouldingReportData(token, userId, consultId);
      setUserName(mouldingResult.info.name);
      setUserId(userId);
      setUserEvaluationDate(formatDate(mouldingResult.info.consultDate));
      setUserNumberSession(mouldingResult.info.consultNum);
      setUserAge(mouldingResult.info.age);
      setUserHeight(mouldingResult.info.height);
      setUserWeigth(mouldingResult.info.weight);
      setUserGender(mouldingResult.info.gender);
      
      var color;
      var currentImc = mouldingResult.imc[0] | 0;

      if(currentImc < 18.5) {
        color = 'rgba(255, 206, 86, 0.9)';
      } else if(currentImc <= 24.9) {
        color = theme.palette.primary.light;
      } else if(currentImc <= 29.9) {
        color = 'rgba(255, 99, 132, 0.9)';
      } else {
        color = theme.palette.error.main;
      }

      setImc({
        ...imc,
        datasets: [
          {
            label: 'Indice',
            data: mouldingResult.imc,
            barPercentage: 1,
            borderWidth: 1,
            borderColor: color,
            backgroundColor: color,
          },
        ],
      });
      setBodyPercent({
        labels: ['Musculatura', 'Grasa', 'Hueso', 'Residual'],
        datasets: [
          {
            label: 'Composicion corporal',
            data: mouldingResult.bodyPercent,
            backgroundColor: [
              'rgba(255, 99, 132, 0.9)',
              'rgba(54, 162, 235, 0.9)',
              'rgba(255, 206, 86, 0.9)',
              'rgba(75, 192, 192, 0.9)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
          },
        ],
      });

      setExtremitiesComposition({
        labels: ['Pantorrilla', 'Muslo', 'Brazo'],
        datasets: [
          {
            label: '% Musculatura',
            data: mouldingResult.extMuscle,
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
          },
          {
            label: '% Grasa',
            data: mouldingResult.extFat,
            borderColor: theme.palette.info.light,
            backgroundColor: theme.palette.info.light,
          },
        ],
      });

      setSomatype({
        labels: ['Endomorfo', 'Mesomorfo', 'Ectomorfo'],
        datasets: [
          {
            data: mouldingResult.soma,
            borderColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
            backgroundColor: ['#5BC0EB', '#C3423F', '#9FCB77'],
          },
        ],
      });

      setBodyKg(mouldingResult.bodyKg);
      setBodyCompositionPercent(mouldingResult.bodyPercent);
      setExtremitiesCompositionFat(mouldingResult.extFat);
      setExtremitiesCompositionMuscle(mouldingResult.extMuscle);
      setMetabolicRisk(mouldingResult.metRisk);
      setCoordinates(mouldingResult.coor);
      const dataX = [0, -6, 6, 0, -4.5, 4.5, 0];
      const dataY = [12, -6, -6, 0, 4.5, 4.5, -7.5];

      // Configuración del gráfico
      const width = 400;
      const height = 280;
      const margin = { top: 20, right: 20, bottom: 20, left: 40 };

      // Crear escalas para ejes X e Y
      const xScale = scaleLinear()
        .domain([-8, 8])
        .range([margin.left, width - margin.right]);

      const yScale = scaleLinear()
        .domain([-10, 16])
        .range([height - margin.bottom, margin.top]);

      const x = xScale(mouldingResult.coor[0]);
      const y = yScale(mouldingResult.coor[1]);
      // Seleccionar el elemento SVG existente en el DOM
      const svg = select(svgRef.current)
        .attr('width', width)
        .attr('height', height);

      // Crear ejes X e Y
      svg.append("g")
        .attr("transform", "translate(0," + (height - margin.bottom) + ")")
        .call(axisBottom(xScale));

      svg.append("g")
        .attr("transform", "translate(" + margin.left + ",0)")
        .call(axisLeft(yScale).tickSize(1));

      // Segmentos
      svg.append("line")
        .attr("x1", xScale(0))
        .attr("y1", yScale(-7.5))
        .attr("x2", xScale(0))
        .attr("y2", yScale(12))
        .attr("stroke", theme.palette.primary.main)
        .attr("stroke-dasharray", "2");

      svg.append("line")
        .attr("x1", xScale(-6))
        .attr("y1", yScale(-6))
        .attr("x2", xScale(6.5))
        .attr("y2", yScale(6.5))
        .attr("stroke", theme.palette.primary.main)
        .attr("stroke-dasharray", "2");

      svg.append("line")
        .attr("x1", xScale(6))
        .attr("y1", yScale(-6))
        .attr("x2", xScale(-6.5))
        .attr("y2", yScale(6.5))
        .attr("stroke", theme.palette.primary.main)
        .attr("stroke-dasharray", "2");

      // Texto
      svg.append("text")
        .attr("x", xScale(0))
        .attr("y", yScale(13))
        .text("Mesomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', theme.palette.secondary.light);

      svg.append("text")
        .attr("x", xScale(-6))
        .attr("y", yScale(-8))
        .text("Endomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', theme.palette.secondary.light);

      svg.append("text")
        .attr("x", xScale(6))
        .attr("y", yScale(-8))
        .text("Ectomorfo")
        .attr("font-size", "10px")
        .attr("text-anchor", "middle")
        .style('fill', theme.palette.secondary.light);
      // Curvas
      const lineData = [
        { x: xScale(-6), y: yScale(-6) },
        { x: xScale(-4.5), y: yScale(4.5) },
        { x: xScale(0), y: yScale(12) }
      ];
      type DataType = { x: any, y: any };
      const lineGenerator = line<DataType>()
        .x(d => d?.x)
        .y(d => d?.y)
        .curve(curveBundle.beta(1.5));

      svg.append("path")
        .datum(lineData)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", theme.palette.primary.light);

      const lineData2 = [
        { x: xScale(-6), y: yScale(-6) },
        { x: xScale(0), y: yScale(-7.5) },
        { x: xScale(6), y: yScale(-6) }
      ];

      svg.append("path")
        .datum(lineData2)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", theme.palette.primary.light);

      const lineData3 = [
        { x: xScale(6), y: yScale(-6) },
        { x: xScale(4.5), y: yScale(4.5) },
        { x: xScale(0), y: yScale(12) }
      ];

      svg.append("path")
        .datum(lineData3)
        .attr("d", lineGenerator)
        .attr("fill", "none")
        .attr("stroke", theme.palette.primary.light);

      svg.append('circle')
        .attr('cx', x)
        .attr('cy', y)
        .attr('r', 3) // Radio del punto
        .attr('fill', theme.palette.primary.light); // Color del punto
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const checkCC = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 85) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 90) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  const checkICE = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 53.5) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 52.5) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  const checkICC = (value: number) => {
    if (userGender === "MUJER") {
      if (value < 0.75) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    } else {
      if (value < 0.90) {
        return "Bajo riesgo";
      }
      return "Alto riesgo";
    }
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <MyCard >
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <MyTitle
                title={userName}
                variant="h5"
                align="left"
                color="primary"
              />
              <MyTitle
                title={"Número de socio: " + userId}
                variant="subtitle2"
                align="left"
                color="dark"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Fecha de evaluación"
                value={userEvaluationDate}
                type="date"
                id="userEvaluationDate"
                name="userEvaluationDate"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4} >
              <MyTextField
                label="Numero de Sesión"
                value={userNumberSession}
                type="text"
                id="numSession"
                name="numSession"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Edad"
                value={userAge + " Años"}
                type="text"
                id="userAge"
                name="userAge"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Peso"
                value={userWeigth}
                type="text"
                id="userWeigth"
                name="userWeigth"
                hasInputAdornment
                inputAdornmentText="kg"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Estatura"
                value={userHeight}
                type="text"
                id="userHeight"
                name="userHeight"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Sexo"
                value={userGender}
                type="text"
                id="userGender"
                name="userGender"
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        </MyCard>

        <MyCard >
          <CardContent>
            <MyItemContainer>
              <MyItem>
                <MyTitle
                  title="ÍNDICE DE MASA CORPORAL (IMC)"
                  variant="h5"
                  align="left"
                  color="primary"
                />
              </MyItem>
              <MyItem>
                <div className="imc-bar">
                  <Bar options={imcOptions} data={imc} />
                </div>
              </MyItem>
              <MyItem>
                <MyTitle
                  title="COMPOSICIÓN CORPORAL"
                  variant="h5"
                  align="left"
                  color="primary"
                />
              </MyItem>

              <MyItem xs={12} md={7}>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Composición</TableCell>
                        <TableCell align="center">Kg</TableCell>
                        <TableCell align="center">%</TableCell>
                        <TableCell align="center">% Ref</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Musculatura</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{bodyKg[0]}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{bodyCompositionPercent[0]}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{`M > 34, H > 42`}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Grasa</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyKg[1]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyCompositionPercent[1]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{`M 21-32, H 8-19`}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Hueso</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyKg[2]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyCompositionPercent[2]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">12 a 16</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Residual</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyKg[3]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{bodyCompositionPercent[3]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">21 a 24</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MyItem>

              <MyItem xs={12} md={5}>
                <Pie options={composicionCorporalOptions} data={bodyPercent} />
              </MyItem>

              <MyItem >
                <MyTitle
                  title="COMPOSICIÓN DE EXTREMIDADES"
                  variant="h5"
                  align="left"
                  color="primary"
                />
              </MyItem>

              <MyItem xs={12} md={7}>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Composición</TableCell>
                        <TableCell align="center">Brazo</TableCell>
                        <TableCell align="center">Muslo</TableCell>
                        <TableCell align="center">Pantorrilla</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">% Musculatura</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{extremitiesCompositionMuscle[0]}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{extremitiesCompositionMuscle[1]}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{extremitiesCompositionMuscle[2]}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">% Grasa</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{extremitiesCompositionFat[0]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{extremitiesCompositionFat[1]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{extremitiesCompositionFat[2]}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MyItem>

              <MyItem xs={12} md={5}>
                <Bar options={composicionExtremidadesOptions} data={extremitiesComposition} />
              </MyItem>

              <MyItem>
                <MyTitle
                  title="RIESGO METABÓLICO"
                  variant="h5"
                  align="left"
                  color="primary"
                />
              </MyItem>

              <MyItem>
                <TableContainer>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Área Corporal</TableCell>
                        <TableCell align="center">Medidas</TableCell>
                        <TableCell align="center">Interpretación</TableCell>
                        <TableCell align="center">Referencia</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Circunferencia de cintura</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{metabolicRisk[0]}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{checkCC(metabolicRisk[0])}</Typography>
                        </TableCell>
                        <TableCell align="center" >
                          <Typography variant="body2" component="div">{`M < 85, H < 90`}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Índice cintura estatura</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{metabolicRisk[1]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{checkICE(metabolicRisk[1])}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{`M < 53.5, H < 52.5`}</Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">Índice cintura cadera</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{metabolicRisk[2]}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{checkICC(metabolicRisk[2])}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2" component="div">{`M < 0.75, H < 0.90`}</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </MyItem>

              <MyItem>
                <MyTitle
                  title="SOMATOTIPO"
                  variant="h5"
                  align="left"
                  color="primary"
                />
              </MyItem>

              <MyItem xs={12} md={7}>
                <Bar options={somatotipoOptions} data={somatype} />
              </MyItem>

              <MyItem xs={12} md={5}>
                <svg ref={svgRef} />
              </MyItem>

            </MyItemContainer>
          </CardContent>
        </MyCard>
      </Container>
    </>
  );
};

export default NutritionReport;
