import server from '../api/server';

export class UserUnlinkService {
    static async unlinkUserDevice(token: string, id: string): Promise<any> {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        };

        try {
            const responseServer = await server.delete(
              `staff/user/${id}`,
              {headers}
            );

            if (responseServer.data.statusCode != 200) {
                console.log("ERROR");
            }

            return responseServer.data.data;
        } catch (error: any) {
            throw new Error(error.response.status);
        }
    }
}
