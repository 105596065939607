import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

interface ModalRoutineProps {
  open: boolean;
  handleClose: () => void;
}

const ModalRoutine: React.FC<ModalRoutineProps> = ({ open, handleClose }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Elige los ejercicios</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="(F) Fuerza" />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="(FU) Funcional"
                />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="(CA) Cardio" />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="(CS) Clases Salon"
                />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="(CY) Cycling" />
              </FormGroup>
            </ListItem>
            <ListItem>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="(NA) Natación"
                />
              </FormGroup>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={handleClose}>Agregar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalRoutine;
