import React from "react";
import { Grid } from "@mui/material";

interface MyItemContainerprops {
    spacing?: number;
    marginBottom?: number;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    children?: React.ReactNode;
}

const MyItemContainer: React.FC<MyItemContainerprops> = ({

    spacing = 2,
    marginBottom = 1,
    direction = 'row',
    justifyContent = 'space-between',
    alignItems = 'center',
    children,

}) => {

    return (
        <Grid container
            spacing={spacing}
            marginBottom={marginBottom}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
        >
            {children}
        </Grid>
    )
};

export default MyItemContainer;