import { useEffect, useState, useContext } from "react";
import { Container, Grid, Paper } from "@mui/material";
import { MyTitle, MyLoading, MyButton, MyTextField, MyAlert, MySelect, MyTooltip } from "../../components/Generic/Index";
import { navigateWithScroll } from "../../functions/navigateUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInfoService } from "../../services/User";
import { NewConsultService } from "../../services/Nutrition/NewConsult";
import { GetConsultObjectivesService } from "../../services/Nutrition/ConsultObjective";
import { NewConsult } from "../../Models/Nutrition/NewConsult";
import { AppContext } from "../../data/state";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import moment from "moment";

const Bioimpedance: React.FC = () => {

  const location: any = useLocation();
  const { state } = useContext(AppContext);
  const token = state.token;
  const navigate = useNavigate();

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formatDate = (date: any) => moment(date).locale("es").format("DD [de] MMMM [de] YYYY");
  const formatMinDate = (date: any) => moment(date).format("YYYY-MM-DD");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);

  const [ticketNumber, setTicketNumber] = useState("");

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [userNutritionID, setUserNutritionID] = useState(0);
  const [userCreatedConsultID, setUserCreatedConsultID] = useState(0);
  const [consultTypeID, setConsultTypeID] = useState(0);
  const [userNumberSession, setUserNumberSession] = useState(0);
  const [userEvaluationDate, setUserEvaluationDate] = useState<string>("");

  const [relaxedArm, setRelaxedArm] = useState("");
  const [contractedArm, setContractedArm] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [midThigh, setMidThigh] = useState("");
  const [calf, setCalf] = useState("");

  const [weight, setWeight] = useState("");
  const [bodyFatPercentage, setBodyFatPercentage] = useState("");
  const [leanBodyMassKg, setLeanBodyMassKg] = useState("");
  const [leanBodyMassPercentage, setLeanBodyMassPercentage] = useState("");
  const [visceralFat, setVisceralFat] = useState("");
  const [size, setSize] = useState("");
  const [IMC, setIMC] = useState("");
  const [hydration, setHydration] = useState("");
  const [bodyFatKg, setBodyFatKg] = useState("");
  const [metabolicAge, setMetabolicAge] = useState("");
  const [goal, setGoal] = useState("");
  const [goalDescription, setGoalDescription] = useState("");
  const [goalList, setGoalList] = useState([]);
  const [nextAppointment, setNextAppointment] = useState("");

  const [alertTitleText, setAlertTitleText] = useState("");
  const [alertMessageText, setAlertMessageText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<any>();

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId);
      setUserNumberSession(location.state.sessionNum + 1);
      setUserNutritionID(location.state.userNutritionID);
      setConsultTypeID(location.state.consultTypeID ? location.state.consultTypeID : 1);
      requestUserInfo(location.state.userId);
    }
    requestConsultObjective();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestUserInfo = async (id: any) => {
    setIsActive(true);
    try {
      const userInfo = await UserInfoService.getUser(token, id);
      setUserName(userInfo.name);

      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const requestConsultObjective = async () => {
    setIsActive(true);
    try {
      const objectivesList = await GetConsultObjectivesService.getObjectives(token);

      const newArray = objectivesList.map((item: any) => {
        return { id: item.id, label: item.objective };
      });

      setGoalList(newArray);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const cancelAlertHandle = () => {
    setIsAlertOpen(false);
  };

  const saveData = async () => {
    setIsActive(true);

    const newConsult: NewConsult = {
      userNutritionID,
      consultDate: new Date().toISOString(),
      objective: goal,
      consultTypeID,
      relaxArm: parseFloat(relaxedArm),
      contractedArm: parseFloat(contractedArm),
      waist: parseFloat(waist),
      hip: parseFloat(hip),
      middleThigh: parseFloat(midThigh),
      calf: parseFloat(calf),
      weight: parseFloat(weight),
      fatAverage: parseFloat(bodyFatPercentage),
      mmKg: parseFloat(leanBodyMassKg),
      mgKg: parseFloat(bodyFatKg),
      mmAverage: parseFloat(leanBodyMassPercentage),
      visceralFat: parseFloat(visceralFat),
      height: parseFloat(size),
      h2O: parseFloat(hydration),
      polarContractID: ticketNumber,
    };

    try {
      const createdConsult = await NewConsultService.createConsult(token, newConsult);
      setUserCreatedConsultID(createdConsult.id);
      setIsActive(false);
      setAlertSeverity("success");
      setAlertTitleText("¡Éxito!");
      setAlertMessageText("Evaluación guardada correctamente!");
      setIsAlertOpen(true);
      setError(false);
      navigateWithScroll(navigate, "/nutrition");
    } catch (error: any) {
      setError(true);
      setIsAlertOpen(true);
      setAlertSeverity("error");
      setAlertTitleText("Error al guardar la evaluación");
      setAlertMessageText("Favor de revisar los datos ingresados.");
      console.log(error);
      setIsActive(false);
    }
  };

  const onChangeDatePicker = (value: any) => {
    setNextAppointment(value);
  };

  const calculateIMC = (currentWeight: any, currentSize: any) => {
    if (currentWeight === "" || currentSize === "" || currentWeight === "0" || currentSize === "0") {
      return "0";
    }

    const weight = parseFloat(currentWeight);
    const size = parseFloat(currentSize) / 100;

    const imc = weight / (size * size);

    return imc.toFixed(1);
  };

  const onChangeWeigth = (value: any) => {
    setIMC(calculateIMC(value, size));
    setWeight(value);
  };

  const onChangeSize = (value: any) => {
    setIMC(calculateIMC(weight, value));
    setSize(value);
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <MyTitle
                title={userName}
                variant="h5"
                align="left"
                color="primary"
                noWrap
              />
              <MyTitle
                title={"Número de socio: " + userId}
                variant="subtitle2"
                align="left"
                color="dark"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={6}>
              <MyTitle
                title={"Sesion #" + userNumberSession}
                variant="h5"
                align="right"
                color="primary"
                gutterBottom
                noWrap
              />
              <MyTitle
                title={"Fecha: " + formatDate(new Date())}
                variant="subtitle2"
                align="right"
                color="dark"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={12}>
              <MyTitle
                title="Circunferencias"
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Brazo relajado"
                value={relaxedArm}
                onChange={(e) => setRelaxedArm(e.target.value)}
                type="number"
                id="relaxedArm"
                name="relaxedArm"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Brazo Contraido"
                value={contractedArm}
                onChange={(e) => setContractedArm(e.target.value)}
                type="number"
                id="contractedArm"
                name="contractedArm"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Cintura"
                value={waist}
                onChange={(e) => setWaist(e.target.value)}
                type="number"
                id="waist"
                name="waist"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Cadera"
                value={hip}
                onChange={(e) => setHip(e.target.value)}
                type="number"
                id="hip"
                name="hip"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Muslo medio"
                value={midThigh}
                onChange={(e) => setMidThigh(e.target.value)}
                type="number"
                id="midThigh"
                name="midThigh"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Pantorrilla"
                value={calf}
                onChange={(e) => setCalf(e.target.value)}
                type="number"
                id="calf"
                name="calf"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <MyTitle
                title="Datos"
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Peso"
                value={weight}
                onChange={(e) => onChangeWeigth(e.target.value)}
                type="number"
                id="weight"
                name="weight"
                hasInputAdornment
                inputAdornmentText="kg"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Talla"
                value={size}
                onChange={(e) => onChangeSize(e.target.value)}
                type="number"
                hasInputAdornment
                inputAdornmentText="cm"
                id="size"
                name="size"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="IMC"
                value={IMC}
                type="text"
                id="IMC"
                name="IMC"
                hasInputAdornment
                inputAdornmentText="%"
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="Porcentaje de Grasa"
                value={bodyFatPercentage}
                onChange={(e) => setBodyFatPercentage(e.target.value)}
                type="number"
                id="bodyFatPercentage"
                name="bodyFatPercentage"
                hasInputAdornment
                inputAdornmentText="%"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="MGKg"
                value={bodyFatKg}
                onChange={(e) => setBodyFatKg(e.target.value)}
                type="number"
                id="bodyFatKg"
                name="bodyFatKg"
                hasInputAdornment
                inputAdornmentText="kg"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="%MM"
                value={leanBodyMassPercentage}
                onChange={(e) => setLeanBodyMassPercentage(e.target.value)}
                type="number"
                id="leanBodyMassPercentage"
                name="leanBodyMassPercentage"
                hasInputAdornment
                inputAdornmentText="%"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="MMkg"
                value={leanBodyMassKg}
                onChange={(e) => setLeanBodyMassKg(e.target.value)}
                type="number"
                id="leanBodyMassKg"
                name="leanBodyMassKg"
                hasInputAdornment
                inputAdornmentText="kg"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Grasa visceral"
                value={visceralFat}
                onChange={(e) => setVisceralFat(e.target.value)}
                type="number"
                id="visceralFat"
                name="visceralFat"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="Edad Metabolica"
                value={metabolicAge}
                onChange={(e) => setMetabolicAge(e.target.value)}
                type="number"
                id="metabolicAge"
                name="metabolicAge"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField
                label="H2O"
                value={hydration}
                onChange={(e) => setHydration(e.target.value)}
                type="number"
                id="hydration"
                name="hydration"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <MySelect
                title="Seleccione su objectivo"
                placeholder="Objetivo de la consulta"
                items={goalList}
                value={goal}
                onChange={(value) => setGoal(value)}
              />
            </Grid>
            <Grid item xs={7}>
              <MyTextField
                label="Descripcion del objetivo"
                value={goalDescription}
                onChange={(e) => setGoalDescription(e.target.value)}
                type="text"
                id="goalDescription"
                name="goalDescription"
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <MyTextField
                label="Proxima cita"
                value={nextAppointment}
                onChange={(e) => onChangeDatePicker(e.target.value)}
                type="date"
                id="nextAppointment"
                name="nextAppointment"
                initialValue={formatDate(new Date())}
                minDate={formatMinDate(tomorrow)}
                fullWidth
              />
            </Grid>
            <Grid item xs={10} md={2}>
                <MyTitle
                  title="Contrato"
                  variant="h5"
                  align="left"
                  color="primary"
                  gutterBottom
                  noWrap
                />
              </Grid>

              <Grid item xs={2} md={10}>
                  <MyTooltip description="Recuerda que el número de contrato está en el recibo, en caso de que el cliente no cuente con uno, se generará un nuevo cobro en Polar.">
                    <HelpTwoToneIcon />
                  </MyTooltip>
                </Grid>

              <Grid item xs={4}>
                <MyTextField
                  label="Numero de contrato"
                  value={ticketNumber}
                  onChange={(e) => setTicketNumber(e.target.value)}
                  type="text"
                  id="ticketNumber"
                  name="ticketNumber"
                  hasInputAdornment
                  inputAdornmentText="#"
                  fullWidth
                />
              </Grid>
            <Grid item xs={9} />
            <Grid item xs={3} paddingTop={2}>
              <MyButton
                title='Guardar Sesion'
                color='primary'
                size='medium'
                handleClick={saveData}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <MyAlert
        open={isAlertOpen}
        severity={alertSeverity}
        title={alertTitleText}
        message={alertMessageText}
        closeText="Cerrar"
        handleClose={cancelAlertHandle}
      />
    </>
  );
};

export default Bioimpedance;
