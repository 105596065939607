import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface MySelectProps {
  title?: string;
  placeholder?: string;
  items?: any[];
  value?: string;
  size?: 'medium' | 'small';
  onChange?: (value: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const MySelect: React.FC<MySelectProps> = ({
  items = [],
  title = 'Placeholder',
  placeholder = 'placeholder',
  value,
  size = 'small',
  onChange,
  readOnly,
  disabled,
}) => {

  const handleChange = (event: SelectChangeEvent) => {
    if (onChange) {
      onChange(event.target.value as string);
    }
  };

  return (

    <FormControl style={{ width: '100%' }} size={size}>
      <InputLabel>{title}</InputLabel>
      <Select
        style={{ borderRadius: '8px' }}
        placeholder={placeholder}

        value={value}
        onChange={handleChange}
        label={title}
        readOnly={readOnly}
        disabled={disabled}
      >
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.id ? item.id : item.label}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MySelect;
