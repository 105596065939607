import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, List, CardContent, CardActions } from "@mui/material";
import {
  MyTitle,
  MyTextField,
  MyButton,
  MyModal,
  MyAlert,
  MySelect,
  MyCard,
  MyListItem,
  MyListItemSkeleton,
  MyItemContainer,
  MyItem,
} from "../../components/Generic/Index";
import { HistoryNutritionService } from "../../services/Nutrition/HistoryNutritionService";
import { ConsultTypesService } from "../../services/Nutrition/ConsultType";
import { AppContext } from "../../data/state";
import { navigateWithScroll } from "../../functions/navigateUtils";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface userSession {
  id: string;
  date: string;
  sessionNum: number;
  completed: boolean;
  hasPhotos: boolean;
  hasReport: boolean;
};

interface consultTypeArray {
  id: string;
  label: string;
};

const Nutrition: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);

  const token = state.token;
  const [userId, setUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isAlertErrorOpen, setIsAlertErrorOpen] = useState(false);
  const [consultTypeID, setConsultTypeID] = useState("");
  const [userNutritionID, setUserNutritionID] = useState(0);

  const [consultOptions, setConsultOptions] = useState<consultTypeArray[]>([]);
  const [userConsults, setUserConsults] = useState<userSession[]>([]);

  const [historyMessageRequest, setHistoryMessageRequest] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [userFound, setUserFound] = useState("Historial de sesiones");

  const [searching, setSearching] = useState(false);

  const formatDate = (date: any) => moment(date).format("DD/MM/YYYY");

  useEffect(() => {
    requestConsultTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestConsultTypes = async () => {
    try {
      const consultTypes = await ConsultTypesService.getTypes(token);

      const newObjArr = consultTypes.map((item: any) => {
        return { id: item.id, label: item.consultType };
      });

      setConsultOptions(newObjArr);
    } catch (error: any) {
      console.log(error);
    }
  };

  const requestUserHistory = async (id: any) => {
    try {
      const userHistory = await HistoryNutritionService.getHistory(token, id);
      console.log(userHistory);

      setUserNutritionID(userHistory.userNutritionID);
      const newObjArr = userHistory.consults.map((item: any) => {
        var sessionNumName = item.consultNum;
        if (item.consultTypeID !== undefined) {
          sessionNumName += `: ${consultOptions[item.consultTypeID - 1].label}`;
        }

        return {
          id: item.id,
          date: formatDate(item.consultDate),
          sessionNum: sessionNumName,
          completed: item.completed,
          hasPhotos: item.hasPhotos,
          hasReport: item.consultType.hasReport,
        };
      });

      setUserFound(`Historial de sesiones del socio ${id}`);
      setUserConsults(newObjArr);
      setSearching(false);
    } catch (error: any) {
      setUserNutritionID(0);
      setErrorCode(error.message);
      if (error.message === "400") {
        setHistoryMessageRequest("El ID ingresado no existe.");
      } else if (error.message === "404") {
        setHistoryMessageRequest(
          `El socio con ID ${id} no cuenta con expediente, ¿desea crearlo?`
        );
      } else if (error.message === "500") {
        setHistoryMessageRequest(`Error en la busqueda, intente de nuevo.`);
        setIsAlertErrorOpen(true);
      }
      setSearching(false);
    }
  };

  const onChangeUserId = (evt: any) => {
    const id = evt.target.validity.valid ? evt.target.value : userId;
    setUserId(id);
  };

  const searchUser = () => {
    setHistoryMessageRequest("");
    setUserNutritionID(0);
    setErrorCode("");
    setSearching(true);
    setUserFound("Historial de sesiones");
    setUserConsults([]);
    requestUserHistory(userId);
  };

  const seeMedicalHistory = () => {
    setIsModalOpen(false);
    setIsAlertOpen(true);
    navigateWithScroll(navigate, "/nutrition/medical-history", {
      state: {
        userId: parseInt(userId),
        readOnly: true,
      },
    });
  };

  const seeHistoricalTables = () => {
    navigateWithScroll(navigate, '/nutrition/historical', {
      state: {
        userId: userId,
      }
    });
  };

  const openConsultTypeModal = () => {
    setIsModalOpen(true);
  };

  const createNewConsult = () => {
    setIsModalOpen(false);
    setIsAlertOpen(true);

    var consultRoute = "";

    const id = consultTypeID.toString();

    if(id === "1") {
      consultRoute = "anthropometric";
      const id = consultTypeID;
    } else if(id === "2") {
      consultRoute = "bioimpedance";
      const id = consultTypeID;
    } else if(id === "3") {
      consultRoute = "menu";
    } else  {
      consultRoute = "equivalences";
    }

    navigateWithScroll(navigate, `/nutrition/${consultRoute}`, {
      state: {
        consultTypeID: consultTypeID,
        userId: parseInt(userId),
        sessionNum: userConsults.length,
        userNutritionID: userNutritionID,
      },
    });
  };

  const createMedicalHistory = () => {
    // Lógica de creación de nueva consulta
    setIsModalOpen(false);
    setIsAlertOpen(true);
    navigateWithScroll(navigate, "/nutrition/medical-history", {
      state: {
        userId: parseInt(userId),
        readOnly: false,
      },
    });
  };

  const completeHandle = (id: any, readOnly: boolean) => {
    navigateWithScroll(navigate, "/nutrition/results", {
      state: {
        id: parseInt(id),
        userId: parseInt(userId),
        readOnly: readOnly,
      },
    });
  };

  const addEvidence = (id: any) => {
    navigateWithScroll(navigate, "/nutrition/evidence", {
      state: {
        consultId: id,
        userId: parseInt(userId),
      },
    });
  };

  const seeReport = (id: string) => {
    navigateWithScroll(navigate, "/nutrition/report", {
      state: {
        consultId: id,
        userId: parseInt(userId),
      },
    });
  };

  const onKeyPress = (e: any) => {
    //[ENTER] key code is 13
    if (e.keyCode === 13 && userId !== "") {
      searchUser();
    }
  };

  return (
    <>
      <Container fixed>
        <MyCard>
          <CardContent>
            <MyItemContainer>
              <MyItem>
                <MyTitle
                  title="Buscar socio"
                  variant="h5"
                  align="left"
                  color="primary"
                  gutterBottom
                  noWrap
                />
              </MyItem>
              <MyItem xs={12} md={9}>
                <MyTextField
                  value={userId}
                  onChange={(e) => onChangeUserId(e)}
                  id="userId"
                  label="Número de socio"
                  type="search"
                  name="userId"
                  pattern="[0-9]*"
                  onKeyPress={(e) => onKeyPress(e)}
                  fullWidth
                />
              </MyItem>
              <MyItem xs={12} md={3}>
                <MyButton
                  title="Buscar"
                  color="primary"
                  size="medium"
                  handleClick={searchUser}
                  disabled={!userId}
                  fullWidth
                />
              </MyItem>
            </MyItemContainer>
          </CardContent>
        </MyCard>
        <MyCard>
          <CardContent>
            <MyItemContainer>
              <Grid item xs={6}>
                <MyTitle
                  title={userFound}
                  variant="h5"
                  align="left"
                  color="dark"
                  gutterBottom
                />
              </Grid>
              {userNutritionID !== 0 ? (
                <>
                  <Grid item xs={2}>
                    <MyButton
                      title="Progreso"
                      color="primary"
                      size="medium"
                      handleClick={seeHistoricalTables}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyButton
                      title="Historial medico"
                      color="primary"
                      size="medium"
                      handleClick={seeMedicalHistory}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <MyButton
                      title="Nueva consulta"
                      color="primary"
                      size="medium"
                      variant="contained"
                      handleClick={openConsultTypeModal}
                      fullWidth
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                {searching ? (
                  <List>
                    <MyListItemSkeleton />
                  </List>
                ) : userConsults.length > 0 ? (
                  <List>
                    <MyListItem
                      userConsults={userConsults}
                      completeHandle={completeHandle}
                      addEvidence={addEvidence}
                      seeReport={seeReport}
                    />
                  </List>
                ) : (
                  <MyCard>
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <MyTitle
                          title={historyMessageRequest}
                          variant="body1"
                          align="center"
                          color="dark"
                          gutterBottom
                        />
                        {errorCode === "404" ? (
                          <MyButton
                            title="Crear historial"
                            color="primary"
                            size="medium"
                            handleClick={createMedicalHistory}
                          />
                        ) : null}
                      </Grid>
                    </CardContent>
                  </MyCard>
                )}
              </Grid>
            </MyItemContainer>
          </CardContent>
        </MyCard>
      </Container>
      <MyModal
        open={isModalOpen}
        title="Nueva Consulta"
        handleClose={() => setIsModalOpen(false)}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MySelect
                title="Opciones de Consulta"
                placeholder="Selecciona una opción"
                items={consultOptions}
                value={consultTypeID}
                onChange={(value) => setConsultTypeID(value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <MyButton
            handleClick={() => setIsModalOpen(false)}
            title="Cancelar"
            color="primary"
            variant="text"
            size="small"
          />
          <MyButton
            handleClick={createNewConsult}
            title="Aceptar"
            color="primary"
            variant="text"
            size="small"
            disabled={consultTypeID === ""}
          />
        </CardActions>
      </MyModal>
      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message="Consulta nutricional agendada correctamente."
        closeText="Cerrar"
        handleClose={() => setIsAlertOpen(false)}
      />
      <MyAlert
        open={isAlertErrorOpen}
        severity="error"
        title="Error"
        message="Se ha producido un error al procesar la solicitud, inténtelo de nuevo."
        closeText="Cerrar"
        handleClose={() => setIsAlertErrorOpen(false)}
      />
    </>
  );
};

export default Nutrition;
