import { createContext, useReducer, useEffect } from "react";
import { useLocalStorage } from './useLocalStoraje';

let AppContext = createContext();

const initialState = {
  isLoggedin: false,
  token: undefined,
  user: undefined,
  permissions: [],
};

let reducer = (state, action) => {
  switch (action.type) {
    case 'set-is-loggedin':
      return { ...state, isLoggedin: action.isLoggedin };
    case 'set-token':
      return { ...state, token: action.token };
    case 'set-user':
      return { ...state, user: action.user };
    case "set-permissions":
      return {...state, permissions: action.permissions};
    default:
      return state;
  }
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState
  }

  let [data, setData] = useLocalStorage('movewellness-staff', fullInitialState);
  let [state, dispatch] = useReducer(reducer, data);
  let value = { state, dispatch };

  useEffect(() => {
    setData(state);
  }, [state, setData]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
