export const navigateWithScroll = (navigate: any,...params:any) => {
    window.scrollTo(0, 0);
    navigate(...params);
}

// Example usage:
/*
    import { navigateWithScroll } from '../functions/navigateUtils';
    const Header = () => {
        const navigate = useNavigate();
        const navigateTo = (path: string) => {
            navigateWithScroll(navigate, path);
        }
        return (
            <Button onClick={() => navigateTo('/path')}>Navigate</Button>
        )
    }
*/

