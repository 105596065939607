import { useEffect, useState, useContext } from "react";
import {
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import {
  MyLoading,
  MyTitle,
  MyButton,
  MyTextField,
  MySelect,
  MyAlert,
  MyDialog,
  MyCard,
} from "../../components/Generic/Index";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { MedicalHistoryService } from "../../services/Nutrition/CreateMedicalHistory";
import { useActive } from "../../hooks/useActive";
import { AppContext } from "../../data/state";
import { MedicalHistoryModel } from "../../Models/Nutrition/MedicalHistoryModel";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateWithScroll } from "../../functions/navigateUtils";
import { UserInfoService } from "../../services/User";
import { GetNutritionMedicalHistoryService } from "../../services/Nutrition/MedicalHistory";
import moment from "moment";

const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");
const MedicalHistory: React.FC = () => {
  const { state } = useContext(AppContext);
  const location: any = useLocation();
  const navigate = useNavigate();

  const token = state.token;
  dayjs.locale("es");

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 6;
  // first page hooks
  const [userId, setUserId] = useState("");
  const [userNutritionID, setUserNutritionID] = useState(0);
  const [userName, setUserName] = useState("");
  const [userBirthDate, setUserBirthDate] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userHeight, setUserHeight] = useState(0);
  const [userWeigth, setUserWeigth] = useState(0);
  const [userMail, setUserMail] = useState("");
  const [userContact, setUserContact] = useState("");
  const [userGender, setUserGender] = useState("");

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogMessageText, setDialogMessageText] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);

  useEffect(() => {
    if (location.state && location.state.userId) {
      if (location.state.readOnly) {
        setIsReadOnly(location.state.readOnly);
        requestUserMedicalHistory(location.state.userId);
      }
      requestUserInfo(location.state.userId);
      setUserId(location.state.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userGenderOptions = [
    { label: "Seleccione una opción" },
    { label: "Masculino" },
    { label: "Femenino" },
    { label: "Otro / Prefiero no responder" },
  ];
  // second page hooks
  const [userHasDiabetes, setUserHasDiabetes] = useState("No");
  const [userHasDiabetesProblems, setUserHasDiabetesProblems] = useState("");
  const userDiabetesOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userHasHypertension, setUserHasHypertension] = useState("No");
  const userHypertensionOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userHasCancer, setUserHasCancer] = useState("No");
  const [userHasCancerProblems, setUserHasCancerProblems] = useState("");
  const userCancerOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userHascardiacProblems, setUserHascardiacProblems] = useState("No");
  const [userHasCardiacProblems, setUserHasCardiacProblems] = useState("");
  const usercardiacProblemsOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userConsumeAlcohol, setUserConsumeAlcohol] = useState("Si");
  const userAlcoholsOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userConsumeAlcoholFrecuency, setUserConsumeAlcoholFrecuency] =
    useState("Ocasionalmente");
  const userAlcoholFrecuencyOptions = [
    { label: "Seleccione una opción" },
    { label: "Nunca" },
    { label: "Ocasionalmente" },
    { label: "Regularmente" },
    { label: "Frecuentemente" },
  ];
  const [userConsumeTobacco, setUserConsumeTobacco] = useState("No");
  const userTobaccoptions = [
    { label: "Seleccione una opción" },
    { label: "Sí" },
    { label: "No" },
  ];
  const [userConsumeTobaccoFrecuency, setUserConsumeTobaccoFrecuency] =
    useState("Nunca");
  const userTobaccoFrecuencyOptions = [
    { label: "Seleccione una opción" },
    { label: "Nunca" },
    { label: "Ocasionalmente" },
    { label: "Regularmente" },
    { label: "Frecuentemente" },
  ];
  const [userOtherConditions, setUserOtherConditions] = useState("");
  const [userInjuries, setUserInjuries] = useState("");

  // third page hooks
  const [relativeHasDiabetes, setRelativeHasDiabetes] = useState("No");
  const [relativeDiabetesProblems, setRelativeDiabetesProblems] = useState("");
  const relativeDiabetesOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [relativeHasHypertension, setRelativeHasHypertension] = useState("No");
  const [relativeHypertensionProblems, setRelativeHypertensionProblems] =
    useState("");
  const relativeHypertensionOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [relativeHasCancer, setRelativeHasCancer] = useState("No");
  const [relativeCancerProblems, setRelativeCancerProblems] = useState("");
  const relativeCancerOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [relativeHasCardiacProblems, setRelativeHascardiacProblems] =
    useState("No");
  const [relativeCardiacProblems, setRelativeCardiacProblems] = useState("");
  const relativeCardiacProblemsOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [relativeHasOtherConditions, setRelativeHasOtherConditions] =
    useState("");
  // fourth page hooks
  const [userPhysicalActivities, setUserPhysicalActivities] = useState("");
  const [userPhysicalActivitiesFrequency, setUserPhysicalActivitiesFrequency] =
    useState("");
  const [userPhysicalActivitiesTime, setUserPhysicalActivitiesTime] =
    useState("");
  const [userReminder, setUserReminder] = useState("");
  // fifth page hooks
  const [userMealCount, setUserMealCount] = useState("");
  const [userMealPreparation, setUserMealPreparation] = useState("");

  const [userFoodPreparation, setUserFoodPreparation] = useState("");
  const foodPreparationOptions = [
    { label: "Seleccione una opción" },
    { label: "Cualquiera" },
    { label: "Cocidos" },
    { label: "Asados" },
    { label: "Guisados" },
  ];

  const [userFavoriteFoods, setUserFavoriteFoods] = useState("");
  const [userLeastFavoriteFoods, setUserLeastFavoriteFoods] = useState("");
  const [userFoodIntolerancesAllergies, setUserFoodIntolerancesAllergies] =
    useState("");
  const [userConsumesSupplements, setUserConsumesSupplements] = useState("No");
  const userConsumesSupplementsOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userSupplementName, setUserSupplementName] = useState("");
  const [userIsMedicated, setUserIsMedicated] = useState("No");
  const userIsMedicatedOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userMedicationName, setUserMedicationName] = useState("");
  const [userConsumesStimulants, setUserConsumesStimulants] = useState("No");
  const userConsumesStimulantsOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [stimulantConsumptionName, setStimulantConsumptionName] = useState("");
  // sixth page hooks
  const [userAwakeTime, setUserAwakeime] = useState("");
  const [userSleepTime, setUserSleepTime] = useState("");
  const [userHasInsomnia, setUserHasInsomnia] = useState("No");
  const userInsomniaOptions = [
    { label: "Seleccione una opción" },
    { label: "Si" },
    { label: "No" },
  ];
  const [userProfession, setUserProfession] = useState("");

  const requestUserMedicalHistory = async (id: any) => {
    setIsActive(true);

    try {
      const userMedicalHistory =
        await GetNutritionMedicalHistoryService.getHistory(token, id);
      setUserHasDiabetes(userMedicalHistory.hasDiabetes ? "Si" : "No");
      setUserHasHypertension(userMedicalHistory.hasHypertension ? "Si" : "No");
      setUserHasCancer(userMedicalHistory.hasCancer ? "Si" : "No");
      setUserHascardiacProblems(userMedicalHistory.heartProblems ? "Si" : "No");
      setUserConsumeAlcohol(userMedicalHistory.alcohol ? "Si" : "No");
      setUserConsumeAlcoholFrecuency(
        userMedicalHistory.alcoholFrecuency
          ? userMedicalHistory.alcoholFrecuency
          : ""
      );
      setUserConsumeTobacco(userMedicalHistory.tobacco ? "Si" : "No");
      setUserConsumeTobaccoFrecuency(
        userMedicalHistory.tobaccoFrecuency
          ? userMedicalHistory.tobaccoFrecuency
          : ""
      );
      setUserOtherConditions(
        userMedicalHistory.otherHealthProblems
          ? userMedicalHistory.otherHealthProblems
          : ""
      );
      setUserInjuries(userMedicalHistory.injuries);
      setRelativeHasDiabetes(userMedicalHistory.familyDiabetes);
      //setRelativeHasHypertension(userMedicalHistory.familyDiabetes);
      setRelativeHasCancer(userMedicalHistory.familyCancer);
      setRelativeHascardiacProblems(userMedicalHistory.familyHeartProblems);
      setUserPhysicalActivities(userMedicalHistory.physicalActivity);
      setUserPhysicalActivitiesFrequency(
        userMedicalHistory.physicalActivityFrecuency
      );
      setUserPhysicalActivitiesTime(userMedicalHistory.physicalActivityTime);
      setUserReminder(
        userMedicalHistory.reminder24Hrs ? userMedicalHistory.reminder24Hrs : ""
      );
      setUserMealCount(userMedicalHistory.numberMeals);
      setUserMealPreparation(userMedicalHistory.cookedFood);
      setUserFavoriteFoods(userMedicalHistory.favoriteFood);
      setUserLeastFavoriteFoods(userMedicalHistory.notFavoriteFood);
      setUserFoodIntolerancesAllergies(
        userMedicalHistory.intoleranceOrAllergies
      );
      setUserConsumesSupplements(
        userMedicalHistory.takeSupplement ? "Si" : "No"
      );
      setUserSupplementName(
        userMedicalHistory.supplementType
          ? userMedicalHistory.supplementType
          : ""
      );
      setUserIsMedicated(userMedicalHistory.takeDrugs ? "Si" : "No");
      setUserMedicationName(
        userMedicalHistory.drugsType ? userMedicalHistory.drugsType : ""
      );
      setUserConsumesStimulants(
        userMedicalHistory.takeStimulants ? "Si" : "No"
      );
      setStimulantConsumptionName(
        userMedicalHistory.stimulantsType
          ? userMedicalHistory.stimulantsType
          : ""
      );
      setUserAwakeime(
        userMedicalHistory.wakeUptime ? userMedicalHistory.wakeUptime : ""
      );
      setUserSleepTime(
        userMedicalHistory.sleepingTime ? userMedicalHistory.sleepingTime : ""
      );
      setUserHasInsomnia(userMedicalHistory.hasImsomnia ? "Si" : "No");
      setUserProfession(
        userMedicalHistory.profession ? userMedicalHistory.profession : ""
      );
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const requestUserInfo = async (id: any) => {
    setIsActive(true);

    try {
      const userInfo = await UserInfoService.getUser(token, id);
      setUserName(userInfo.name);
      const auxHeight =
        userInfo.height < 10 ? userInfo.height * 100 : userInfo.height;
      setUserHeight(auxHeight);
      setUserWeigth(userInfo.weight);
      setUserBirthDate(formatDate(userInfo.birthDate));
      setUserContact(userInfo.phoneNumber);
      setUserMail(userInfo.email);
      setUserGender(userInfo.gender);

      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const calculateAge = (userBirthDate: string) => {
    const now = dayjs();
    const age = now.diff(userBirthDate, "year");
    setUserAge(age.toString());
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handlePrevious = () => {
    const previousStep = activeStep - 1;
    if (previousStep >= 0) {
      handleStepChange(previousStep);
    }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < totalSteps) {
      handleStepChange(nextStep);
    }
  };

  const cancelAlertHandle = () => {
    setIsAlertOpen(false);
  };

  const saveData = async () => {
    setIsActive(true);
    // save data logic
    const data: MedicalHistoryModel = {
      userID: userId,
      hasDiabetes: userHasDiabetes === "Si",
      hasHypertension: userHasHypertension === "Si",
      hasCancer: userHasCancer === "Si",
      heartProblems: userHascardiacProblems,
      injuries: parseInt(userInjuries),
      otherHealthProblems: userOtherConditions,
      alcohol: userConsumeAlcohol === "Si",
      tobacco: userConsumeTobacco === "Si",
      alcoholFrecuency: userConsumeAlcoholFrecuency,
      tobaccoFrecuency: userConsumeTobacco,
      familyDiabetes: relativeHasDiabetes,
      familyCancer: relativeHasCancer,
      familyHeartProblems: relativeHasCardiacProblems,
      familyOtherHealthProblems: relativeHasOtherConditions,
      physicalActivityTime: userPhysicalActivitiesTime,
      physicalActivityFrecuency: userPhysicalActivitiesFrequency,
      physicalActivity: userPhysicalActivities,
      numberMeals: parseInt(userMealCount),
      foodPreparation: userFoodPreparation,
      grilledFood: "",
      cookedFood: "",
      stewFood: "",
      favoriteFood: userFavoriteFoods,
      notFavoriteFood: userLeastFavoriteFoods,
      intoleranceOrAllergies: userFoodIntolerancesAllergies,
      takeSupplement: userConsumesSupplements === "Si",
      supplementType: userSupplementName,
      takeDrugs: userIsMedicated === "Si",
      drugsType: userMedicationName,
      takeStimulants: userConsumesStimulants === "Si",
      stimulantsType: stimulantConsumptionName,
      sleepingTime: userSleepTime,
      wakeUptime: userAwakeTime,
      hasImsomnia: userHasInsomnia === "Si",
      profession: userProfession,
      reminder24Hrs: userReminder,
    };

    try {
      const consultData = await MedicalHistoryService.createMedicalHistory(
        token,
        data
      );
      setUserNutritionID((prev) => {
        return consultData.id;
      });
      setIsAlertOpen(true);
      setIsDialogOpen(true);
      setDialogTitleText("Usuario creado con exito!");
      setDialogMessageText("Desea iniciar una evaluación antropométrica?");
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const onChangeBirthSelect = (date: string) => {
    calculateAge(date);
    setUserBirthDate(date);
  };

  const cancelDialoghandle = () => {
    setIsDialogOpen(false);
    navigateWithScroll(navigate, "/nutrition");
  };

  const confirmDialoghandle = () => {
    setIsDialogOpen(false);
    navigateWithScroll(navigate, "/nutrition/anthropometric", {
      state: {
        userId: parseInt(userId),
        sessionNum: 0,
        userNutritionID: userNutritionID,
      },
    });
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <Grid marginTop={3}>
          <Stepper activeStep={activeStep} nonLinear alternativeLabel>
            <Step onClick={() => handleStepChange(0)}>
              <StepLabel>Datos personales</StepLabel>
            </Step>

            <Step onClick={() => handleStepChange(1)}>
              <StepLabel>Antecedentes personales</StepLabel>
            </Step>

            <Step onClick={() => handleStepChange(2)}>
              <StepLabel>Antecedentes familiares</StepLabel>
            </Step>

            <Step onClick={() => handleStepChange(3)}>
              <StepLabel>Actividad física</StepLabel>
            </Step>

            <Step onClick={() => handleStepChange(4)}>
              <StepLabel>Evaluación dietética</StepLabel>
            </Step>

            <Step onClick={() => handleStepChange(5)}>
              <StepLabel>Recordatorios</StepLabel>
            </Step>
          </Stepper>
        </Grid>

        {activeStep === 0 && (
          <>
            <MyCard>
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Datos personales"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Información básica del usuario (Datos del sistema Polar)"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={8}>
                  <MyTextField
                    label="Nombre del socio"
                    value={userName}
                    type="text"
                    id="userName"
                    name="userName"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    value={userId}
                    id="userId"
                    label="Número de socio"
                    type="number"
                    name="userId"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    value={userBirthDate}
                    id="userBirthDate"
                    label="Fecha de nacimiento"
                    type="date"
                    name="userBirthDate"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Estatura"
                    value={userHeight}
                    type="number"
                    id="userId"
                    name="userId"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Peso"
                    value={userWeigth}
                    type="number"
                    id="userWeigth"
                    name="userWeigth"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Contacto"
                    value={userContact}
                    type="text"
                    id="userContact"
                    name="userContact"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Correo"
                    value={userMail}
                    type="text"
                    id="userMail"
                    name="userMail"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Sexo"
                    value={userGender}
                    type="text"
                    id="userGender"
                    name="userGender"
                    fullWidth
                    readOnly
                    disabled
                  />
                </Grid>
              </Grid>
            </MyCard>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Antecedentes personales"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Enfermedades o condiciones médicas previas"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Diabetes"
                    placeholder="Selecciona una opción"
                    items={userDiabetesOptions}
                    value={userHasDiabetes}
                    onChange={(value) => setUserHasDiabetes(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Hipertensión"
                    placeholder="Selecciona una opción"
                    items={userHypertensionOptions}
                    value={userHasHypertension}
                    onChange={(value) => setUserHasHypertension(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Cáncer"
                    placeholder="Selecciona una opción"
                    items={userCancerOptions}
                    value={userHasCancer}
                    onChange={(value) => setUserHasCancer(value)}
                  />
                </Grid>
                {userHasDiabetes !== "" && userHasDiabetes === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={userHasDiabetesProblems}
                      onChange={(e) =>
                        setUserHasDiabetesProblems(e.target.value)
                      }
                      id="userHasDiabetesProblems"
                      label="Describa los problemas diabeticos."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
                {userHasCancer !== "" && userHasCancer === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={userHasCancerProblems}
                      onChange={(e) => setUserHasCancerProblems(e.target.value)}
                      id="userHasCancerProblems"
                      label="Describa los problemas canceriguenos."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <MySelect
                    title="Problemas Cardiacos"
                    placeholder="Selecciona una opción"
                    items={usercardiacProblemsOptions}
                    value={userHascardiacProblems}
                    onChange={(value) => setUserHascardiacProblems(value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MySelect
                    title="Consume bebidas alcohólicas"
                    placeholder="Selecciona una opción"
                    items={userAlcoholsOptions}
                    value={userConsumeAlcohol}
                    onChange={(value) => setUserConsumeAlcohol(value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MySelect
                    title="¿Con que frecuencia?"
                    placeholder="Selecciona una opción"
                    items={userAlcoholFrecuencyOptions}
                    value={userConsumeAlcoholFrecuency}
                    onChange={(value) => setUserConsumeAlcoholFrecuency(value)}
                  />
                </Grid>
                {userHascardiacProblems !== "" &&
                userHascardiacProblems === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={userHasCardiacProblems}
                      onChange={(e) =>
                        setUserHasCardiacProblems(e.target.value)
                      }
                      id="userHasCardiacProblems"
                      label="Describa los problemas cardiacos."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <MyTextField
                    value={userOtherConditions}
                    onChange={(e) => setUserOtherConditions(e.target.value)}
                    id="userOtherConditions"
                    label="Otras condiciones"
                    type="text"
                    name="userOtherConditions"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <MySelect
                    title="Consume tabaco"
                    placeholder="Selecciona una opción"
                    items={userTobaccoptions}
                    value={userConsumeTobacco}
                    onChange={(value) => setUserConsumeTobacco(value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <MySelect
                    title="¿Con que frecuencia?"
                    placeholder="Selecciona una opción"
                    items={userTobaccoFrecuencyOptions}
                    value={userConsumeTobaccoFrecuency}
                    onChange={(value) => setUserConsumeTobaccoFrecuency(value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyTextField
                    value={userInjuries}
                    onChange={(e) => setUserInjuries(e.target.value)}
                    label="Lesiones"
                    type="text"
                    placeholder="Ingrese las lesiones que considere importantes."
                    id="userInjuries"
                    name="userInjuries"
                    multiline
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {activeStep === 2 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Antecedentes familiares"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Familiares directos con alguna de las siguientes enfermedades"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Diabetes"
                    placeholder="Selecciona una opción"
                    items={relativeDiabetesOptions}
                    value={relativeHasDiabetes}
                    onChange={(value) => setRelativeHasDiabetes(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Hipertensión"
                    placeholder="Selecciona una opción"
                    items={relativeHypertensionOptions}
                    value={relativeHasHypertension}
                    onChange={(value) => setRelativeHasHypertension(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Cáncer"
                    placeholder="Selecciona una opción"
                    items={relativeCancerOptions}
                    value={relativeHasCancer}
                    onChange={(value) => setRelativeHasCancer(value)}
                  />
                </Grid>
                {relativeHasDiabetes !== "" && relativeHasDiabetes === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={relativeDiabetesProblems}
                      onChange={(e) =>
                        setRelativeDiabetesProblems(e.target.value)
                      }
                      id="relativeDiabetesProblems"
                      label="Describa los problemas diabeticos de los familiares."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
                {relativeHasCancer !== "" && relativeHasCancer === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={relativeCancerProblems}
                      onChange={(e) =>
                        setRelativeCancerProblems(e.target.value)
                      }
                      id="relativeCancerProblems"
                      label="Describa los problemas canceriguenos de los familiares."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <MySelect
                    title="Problemas Cardiacos"
                    placeholder="Selecciona una opción"
                    items={relativeCardiacProblemsOptions}
                    value={relativeHasCardiacProblems}
                    onChange={(value) => setRelativeHascardiacProblems(value)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <MyTextField
                    value={relativeHasOtherConditions}
                    onChange={(e) =>
                      setRelativeHasOtherConditions(e.target.value)
                    }
                    id="relativeHasOtherConditions"
                    label="Otras condiciones"
                    type="text"
                    name="relativeHasOtherConditions"
                    fullWidth
                  />
                </Grid>
                {relativeHasCardiacProblems !== "" &&
                relativeHasCardiacProblems === "Si" ? (
                  <Grid item xs={12}>
                    <MyTextField
                      value={relativeCardiacProblems}
                      onChange={(e) =>
                        setRelativeCardiacProblems(e.target.value)
                      }
                      id="relativeCardiacProblems"
                      label="Describa los problemas cardiacos de los familiares."
                      type="text"
                      name="relativeHasOtherConditions"
                      fullWidth
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Paper>
          </>
        )}
        {activeStep === 3 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Actividad física"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Tipo de ejercicio practicado regularmente"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Actividad física"
                    value={userPhysicalActivities}
                    onChange={(e) => setUserPhysicalActivities(e.target.value)}
                    type="text"
                    id="userPhysicalActivities"
                    name="userPhysicalActivities"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Frecuencia"
                    value={userPhysicalActivitiesFrequency}
                    onChange={(e) =>
                      setUserPhysicalActivitiesFrequency(e.target.value)
                    }
                    type="text"
                    id="userPhysicalActivitiesFrequency"
                    name="userPhysicalActivitiesFrequency"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Tiempo dedicado"
                    value={userPhysicalActivitiesTime}
                    onChange={(e) =>
                      setUserPhysicalActivitiesTime(e.target.value)
                    }
                    type="text"
                    id="userPhysicalActivitiesTime"
                    name="userPhysicalActivitiesTime"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTextField
                    label="Recordatorio 24 Horas"
                    value={userReminder}
                    onChange={(e) => setUserReminder(e.target.value)}
                    type="text"
                    id="userReminder"
                    name="userReminder"
                    multiline={true}
                    hasHelperText
                    helperText="Maximo 1000 caracteres"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {activeStep === 4 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Evaluación dietética"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Patrones alimentarios habituales"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Número de comidas al día"
                    value={userMealCount}
                    onChange={(e) => setUserMealCount(e.target.value)}
                    type="number"
                    id="userMealCount"
                    name="userMealCount"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Preparación de alimentos"
                    value={userMealPreparation}
                    onChange={(e) => setUserMealPreparation(e.target.value)}
                    type="text"
                    id="userMealPreparation"
                    name="userMealPreparation"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MySelect
                    title="Preparación de alimentos preferida"
                    placeholder="Selecciona una opción"
                    items={foodPreparationOptions}
                    value={userFoodPreparation}
                    onChange={(value) => setUserFoodPreparation(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Alimentos favoritos"
                    value={userFavoriteFoods}
                    onChange={(e) => setUserFavoriteFoods(e.target.value)}
                    type="text"
                    id="userFavoriteFoods"
                    name="userFavoriteFoods"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Alimentos no favoritos"
                    value={userLeastFavoriteFoods}
                    onChange={(e) => setUserLeastFavoriteFoods(e.target.value)}
                    type="text"
                    id="userLeastFavoriteFoods"
                    name="userLeastFavoriteFoods"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Intolerancia alimentaria o alergias"
                    value={userFoodIntolerancesAllergies}
                    onChange={(e) =>
                      setUserFoodIntolerancesAllergies(e.target.value)
                    }
                    type="text"
                    id="userFoodIntolerancesAllergies"
                    name="userFoodIntolerancesAllergies"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MySelect
                    title="¿Consume algun suplemento alimenticio?"
                    placeholder="Selecciona una opción"
                    items={userConsumesSupplementsOptions}
                    value={userConsumesSupplements}
                    onChange={(value) => setUserConsumesSupplements(value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="¿Cual?"
                    value={userSupplementName}
                    onChange={(e) => setUserSupplementName(e.target.value)}
                    type="text"
                    id="userSupplementName"
                    name="userSupplementName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MySelect
                    title="¿Consume algun farmaco?"
                    placeholder="Selecciona una opción"
                    items={userIsMedicatedOptions}
                    value={userIsMedicated}
                    onChange={(value) => setUserIsMedicated(value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="¿Cual?"
                    value={userMedicationName}
                    onChange={(e) => setUserMedicationName(e.target.value)}
                    type="text"
                    id="userMedicationName"
                    name="userMedicationName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MySelect
                    title="¿Consume algun estimulante?"
                    placeholder="Selecciona una opción"
                    items={userConsumesStimulantsOptions}
                    value={userConsumesStimulants}
                    onChange={(value) => setUserConsumesStimulants(value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="¿Cual?"
                    value={stimulantConsumptionName}
                    onChange={(e) =>
                      setStimulantConsumptionName(e.target.value)
                    }
                    type="text"
                    id="stimulantConsumptionName"
                    name="stimulantConsumptionName"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {activeStep === 5 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Datos Adicionales"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title="Información variada"
                    variant="subtitle2"
                    align="left"
                    color="secondary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="Hora de despertar"
                    value={userAwakeTime}
                    onChange={(e) => setUserAwakeime(e.target.value.toString())}
                    type="time"
                    id="userAwakeTime"
                    name="userAwakeTime"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <MyTextField
                    label="Hora de dormir"
                    value={userSleepTime}
                    onChange={(e) =>
                      setUserSleepTime(e.target.value.toString())
                    }
                    type="time"
                    id="userSleepTime"
                    name="userSleepTime"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MySelect
                    title="¿Padece insomnio?"
                    placeholder="Selecciona una opción"
                    items={userInsomniaOptions}
                    value={userHasInsomnia}
                    onChange={(value) => setUserHasInsomnia(value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="Profesión"
                    value={userProfession}
                    onChange={(e) => setUserProfession(e.target.value)}
                    type="text"
                    id="userProfession"
                    name="userProfession"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                  {isReadOnly ? (
                    <MyButton
                      title="Regresar al inicio"
                      color="primary"
                      size="medium"
                      handleClick={cancelDialoghandle}
                      fullWidth
                    />
                  ) : (
                    <MyButton
                      title="Guardar historia clinica"
                      color="primary"
                      size="medium"
                      handleClick={saveData}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <MyButton
              title="Anterior"
              color="primary"
              handleClick={handlePrevious}
            />
          </div>
          {activeStep + 1 !== totalSteps && (
            <div>
              <MyButton
                title="Siguiente"
                color="primary"
                handleClick={handleNext}
              />
            </div>
          )}
        </div>
      </Container>

      <MyDialog
        open={isDialogOpen}
        title={dialogTitleText}
        message={dialogMessageText}
        cancelTextButton="Volver a historial"
        confirmTextButton="Aceptar"
        cancelHandle={cancelDialoghandle}
        confirmHandle={confirmDialoghandle}
      />

      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message="Historia clínica del usuario guardada correctamente."
        closeText="Cerrar"
        handleClose={cancelAlertHandle}
      />
    </>
  );
};

export default MedicalHistory;
