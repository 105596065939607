import { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Chip,
  List,
  ListItem,
  Divider,
  ListItemButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "../../theme/colors";
import ModalRoutine from "../../ViewComponents/Routine/ModalRoutine";

import "./CreateRoutine.css";

const CreateRoutine: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ModalRoutine open={isOpen} handleClose={() => setIsOpen(false)} />
      <div className="container-app">
        <Grid>
          <Grid marginTop={3}>
            <Typography
              variant="h4"
              component="h4"
              color="primary"
              textAlign={"center"}
            >
              Crear rutina
            </Typography>
          </Grid>
          <Grid>
            <Card>
              <CardContent>
                <Grid item lg paddingBottom={2}>
                  <TextField
                    id="numberClient"
                    label="Numero de socio del cliente"
                    variant="standard"
                    color="primary"
                    fullWidth
                  />
                </Grid>

                <Grid item lg paddingBottom={2}>
                  <TextField
                    id="numberClient"
                    label="Nombre del cliente"
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={"Mario Moreno"}
                  />
                </Grid>

                <Grid item lg paddingBottom={2}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="typeTraining-label">
                      Agregar periodo
                    </InputLabel>
                    <Select
                      labelId="typeTraining-label"
                      id="typeTraining"
                      onChange={() => {}}
                      defaultValue={"0"}
                    >
                      <MenuItem value={"0"}>Selecciona una semana</MenuItem>
                      <MenuItem value={"1"}>Semana 1</MenuItem>
                      <MenuItem value={"2"}>Semana 2</MenuItem>
                      <MenuItem value={"3"}>Semana 3</MenuItem>
                      <MenuItem value={"4"}>Semana 4</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg paddingBottom={2}>
                  <Button color="primary" variant="contained" fullWidth>
                    Agregar
                  </Button>
                </Grid>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Información basica
                </Typography>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    color="primary"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                  >
                    <Typography>Semana 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem>
                        <ListItemButton
                          onClick={() => setIsOpen((prev) => !prev)}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body1" component="div">
                              Lunes
                            </Typography>
                            <Chip
                              label="F"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Martes
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body1" component="div">
                              Miercoles
                            </Typography>
                            <Chip
                              label="F/FU"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Jueves
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body1" component="div">
                              Viernes
                            </Typography>
                            <Chip
                              label="CA"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Sabado
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Domingo
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Button color="error" variant="contained" size="small">
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button color="primary" variant="contained" size="small">
                          Detalles
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                    }}
                  >
                    <Typography>Semana 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      <ListItem>
                        <ListItemButton>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              onClick={() => setIsOpen(true)}
                            >
                              Lunes
                            </Typography>
                            <Chip
                              label="F"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Martes
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body1" component="div">
                              Miercoles
                            </Typography>
                            <Chip
                              label="F/FU"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Jueves
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography variant="body1" component="div">
                              Viernes
                            </Typography>
                            <Chip
                              label="CA"
                              variant="filled"
                              color="default"
                            ></Chip>
                          </Grid>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Sabado
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem>
                        <ListItemButton>
                          <Typography variant="body1" component="div">
                            Domingo
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Button color="error" variant="contained" size="small">
                          Eliminar
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button color="primary" variant="contained" size="small">
                          Detalles
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CreateRoutine;
