import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Grid,
  List,
  ListItem,
  CardActions,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  MyCard,
  MyLoading,
  MyTitle,
  MyTextField,
  MyButton,
  MyAlert,
  MySelect,
  MyAutoCompleteInput,
} from "../../components/Generic/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { UserCalculatorInfoService } from "../../services/Nutrition/CalculatorInfo";
import { GetNutritionFormulasService } from "../../services/Nutrition/Formulas";
import { GetNutritionObjectivesService } from "../../services/Nutrition/Objectives";
import { CreateUserConsultCalculatorService } from "../../services/Nutrition/CreateUserConsultCalculator";
import { GetCalculatorByIdService } from "../../services/Nutrition/CalculatorById";
import { GetCalculatorConsultService } from "../../services/Nutrition/CalculatorConsult";
import { GetNutritionMETService } from "../../services/Nutrition/ActivityMET";
import { CalculatorExtraFieldsService } from "../../services/Nutrition/CalculatorExtraFields";
import { CreateActivityMETService } from "../../services/Nutrition/CreateActivityMET";
import { GetNutritionMETByConsultIdService } from "../../services/Nutrition/ActivityMETByConsultId";
import { RemoveMETService } from "../../services/Nutrition/RemoveMET";
import { AppContext } from "../../data/state";
import { navigateWithScroll } from "../../functions/navigateUtils";

const Calculator: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();

  const { state } = useContext(AppContext);
  const [isActive, setIsActive] = useState(false);

  const token = state.token;
  const [warningCalculatorResponse, setWarningCalculatorResponse] =
    useState("");
  const [alertText, setAlertText] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 3;
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [consultId, setConsultId] = useState(0);
  const [userGender, setUserGender] = useState("");
  const [calculatorId, setCalculatorId] = useState(0);

  const [formulas, setFormulas] = useState<any[]>([]);
  const [selectedFormula, setSelectedFormula] = useState("");
  const [objectives, setObjectives] = useState<any[]>([]);
  const [selectedObjective, setSelectedObjective] = useState("");
  const [activities, setActivities] = useState<any[]>([]);

  interface ActivityBody {
    id: number;
    label: string;
    mets: number;
  };

  const [selectedActivity, setSelectedActivity] = useState<ActivityBody>({
    id: 0,
    label: "",
    mets: 0,
  });
  const [selectedActivityName, setSelectedActivityName] = useState("");

  const [userHeight, setUserHeight] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userActualWeigth, setUserActualWeigth] = useState("");
  const [userAF, setUserAF] = useState("");
  const [userObjetiveWeigth, setUserObjetiveWeigth] = useState("");
  const [userIMC, setUserIMC] = useState("");
  const [actionPercent, setActionPercent] = useState("");

  const [userMetActivityEnergy, setUserMetActivityEnergy] = useState("");
  const [userMetActivityTime, setUserMetActivityTime] = useState("");
  const [userMetActivityName, setUserMetActivityName] = useState("");
  const [userMetActivityList, setUserMetActivityList] = useState<any[]>([]);

  const [psGKG, setPsGKG] = useState("");
  const [psPercent, setPsPercent] = useState<number>(0.0);
  const [psKCAL, setPsKCAL] = useState<number>(0.0);
  const [psG, setPsG] = useState<number>(0.0);

  const [hcGKG, setHcGKG] = useState("");
  const [hcPercent, setHcPercent] = useState<number>(0.0);
  const [hcKCAL, setHcKCAL] = useState<number>(0.0);
  const [hcG, setHcG] = useState<number>(0.0);

  const [lpGKG, setLpGKG] = useState("");
  const [lpPercent, setLpPercent] = useState<number>(0.0);
  const [lpKCAL, setLpKCAL] = useState<number>(0.0);
  const [lpG, setLpG] = useState<number>(0.0);

  const [energy, setEnergy] = useState<number>(0.0);
  const [requiredEnergy, setRequiredEnergy] = useState<number>(0.0);

  const [resultGET, setResultGET] = useState<number>(0.0);
  const [resultGEB, setResultGEB] = useState<number>(0.0);

  const [differencePercent, setDifferencePercent] = useState<number>(0.0);
  const [differenceKCAL, setDifferenceKCAL] = useState<number>(0.0);

  useEffect(() => {
    //begin data load
    (async () => {
      if (location.state && location.state.userId) {
        setUserId(location.state.userId);
        await requestUserCalculatorInfo(location.state.consultId);
        await setConsultId(location.state.consultId);
        await requestConsultActivityMET(location.state.consultId);
      }

      await requestFormulas();
      await requestObjectives();
      await requestActivities();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  };

  const calculateIMC = (currentWeight: any, currentSize: any) => {
    if (currentWeight === 0 || currentSize === 0) {
      return 0.0;
    }

    const size = parseFloat(currentSize) / 100.0;

    return parseFloat(currentWeight) / (size * size);
  };

  const requestUserCalculatorInfo = async (id: any) => {
    setIsActive(true);
    try {
      const calculatorUserInfo = await UserCalculatorInfoService.getData(
        token,
        id
      );
      setUserIMC(
        calculateIMC(
          calculatorUserInfo.weight,
          calculatorUserInfo.height
        ).toFixed(1)
      );

      setUserName(calculatorUserInfo.userName);
      setUserAge(getAge(calculatorUserInfo.birthDate));
      setUserActualWeigth(calculatorUserInfo.weight);
      setUserHeight(calculatorUserInfo.height);
      setUserGender(calculatorUserInfo.gender);

      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const requestConsultActivityMET = async (id: number) => {
    setIsActive(true);

    try {
      const activitiesByConsult =
        await GetNutritionMETByConsultIdService.getConsultActivities(token, id);

      if (activitiesByConsult.length > 0) {
        const newArr = activitiesByConsult.map((item: any) => {
          return {
            id: item.id,
            nutritionMETId: item.nutritionMET.id,
            label: item.nutritionMET.activity,
            met: item.nutritionMET.mets.toFixed(1),
            time: item.minutes,
            energyMET: item.energyMET.toFixed(1),
          };
        });

        setUserMetActivityList(newArr);
      }
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const removeActivityRequest = async (id: number) => {
    setIsActive(true);

    try {
      await RemoveMETService.remove(token, id);

      setIsAlertOpen(true);
      setAlertText("Actividad eliminada con exito");
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const removeActivity = (id: any) => {
    setUserMetActivityList((current) => {
      return userMetActivityList.filter((item) => id !== item.id);
    });
    removeActivityRequest(id);
  };

  const requestFormulas = async () => {
    setIsActive(true);
    try {
      const formulas = await GetNutritionFormulasService.getFormulas(token);
      const newArr = formulas.map((item: any) => {
        return {
          id: item.id,
          label: item.formula,
          active: item.active,
        };
      });

      setFormulas(newArr);

      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const requestActivities = async () => {
    setIsActive(true);
    try {
      const activities = await GetNutritionMETService.getActivities(token);

      const newArr = activities.mets.map((item: any) => {
        return {
          id: item.id,
          label: item.activity,
          mets: item.mets,
        };
      });

      setActivities(newArr);

      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const requestObjectives = async () => {
    setIsActive(true);
    try {
      const objetives = await GetNutritionObjectivesService.getObjectives(
        token
      );

      const newArr = objetives.map((item: any) => {
        return {
          id: item.id,
          label: item.objective,
          active: item.active,
        };
      });

      setObjectives(newArr);
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const requestCreateCalculator = async () => {
    setIsActive(true);
    try {
      const data = {
        nutritionConsultID: consultId,
        physicalActivityPercent: userAF !== "" ? parseFloat(userAF) : 0.0,
        objectiveWeight:
          userObjetiveWeigth !== "" ? parseFloat(userObjetiveWeigth) : 0.0,
        nutritionObjectiveID: selectedObjective,
        actionPercent: actionPercent !== "" ? parseInt(actionPercent) : 0.0,
        nutritionFormulaID: selectedFormula,
      };

      const calculatorResponse =
        await CreateUserConsultCalculatorService.createConsultCalculator(
          token,
          data
        );

      setWarningCalculatorResponse("");
      setCalculatorId((current) => {
        return calculatorResponse.id;
      });
      setResultGEB(parseFloat(calculatorResponse.geb.toFixed(1)));
      setResultGET(parseFloat(calculatorResponse.get.toFixed(1)));
      setRequiredEnergy(
        parseFloat(calculatorResponse.requiredEnergy.toFixed(1))
      );
      setIsActive(false);
    } catch (error: any) {
      setWarningCalculatorResponse(
        "Error al calcular GEB, GET y Energia requerida, favor de revisar el requerimiento energético."
      );
      console.log(error);
      setIsActive(false);
    }
  };

  const goToPhotoEvidence = async () => {
    setIsActive(true);
    const data = {
      consultCalculatorID: calculatorId,
      ps: psGKG,
      hc: hcGKG,
      lp: lpGKG,
    };

    try {
      const extraFieldsRes = await CalculatorExtraFieldsService.addExtraFields(
        token,
        data
      );
      setIsAlertOpen(true);
      setAlertText("Datos del calculadora guardados correctamente.");
      setTimeout(() => {
        navigateWithScroll(navigate, "/nutrition/fileUpload", {
          state: { consultId: consultId, userId: userId },
        });
      }, 800);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const activitySelected = (val: any) => {
    const result = activities.find((item) => item.id === val.id);

    if (result) {
      setSelectedActivity(val);
      setSelectedActivityName(result.label);
      setUserMetActivityName(result.label);
      setUserMetActivityEnergy(result.mets);
    }
  };

  const handleActivitySelected = (val: any) => {
    setSelectedActivity(val);
    const result = activities.filter((item) => item.id === val);
    setUserMetActivityEnergy(result[0].mets);
    setUserMetActivityName(result[0].label);
  };

  const handleStepChange = (step: number) => {
    if (step + 1 === totalSteps) {
      requestCreateCalculator();
    }
    setActiveStep(step);
  };

  const handlePrevious = () => {
    const previousStep = activeStep - 1;
    if (previousStep >= 0) {
      handleStepChange(previousStep);
    }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < totalSteps) {
      requestCreateCalculator();
      handleStepChange(nextStep);
    }
  };

  const createNewActivity = async () => {
    //create activity logic
    setIsActive(true);

    const dataToSend = {
      nutritionConsultID: consultId,
      nutritionMETID: selectedActivity.id,
      minutes: userMetActivityTime,
    };

    try {
      const res = await CreateActivityMETService.addActivity(token, dataToSend);

      const data = {
        id: selectedActivity.id,
        label: userMetActivityName,
        met: userMetActivityEnergy,
        time: userMetActivityTime,
        energyMET: res.energyMET,
      };

      setIsActive(false);

      setUserMetActivityList((prev) => [...prev, data]);
      setIsAlertOpen(true);
      setAlertText("Actividadd agregada correctamente.");

      setSelectedActivity({
        id: 0,
        label: "",
        mets: 0,
      });
      setUserMetActivityEnergy("");
      setUserMetActivityName("");
      setUserMetActivityTime("");
      setIsActive(false);
    } catch (error: any) {
      setAlertText("Error al agregar actividad.");
      console.log(error);
      setIsActive(false);
    }
  };

  const cancelAlertHandle = () => {
    setIsAlertOpen(false);
  };

  const onChangePs = (val: any) => {
    if (val !== "") {
      if (val === "0" || val === "0." || val === ".") {
        setPsGKG("0");
        setPsPercent(0.0);
        setHcPercent(0.0);
        setLpPercent(0.0);
        setEnergy(0.0);
        setDifferenceKCAL(0.0);
        setDifferencePercent(0.0);
        setPsG(0.0);
        setPsKCAL(0.0);
      } else {
        const value = parseFloat(val);
        const currentPsGKG = value;
        const currentPsG = value * parseFloat(userActualWeigth);
        const currentPsKCAL = Math.round(currentPsG * 4.0);
        const currentEnergy = currentPsKCAL + hcKCAL + lpKCAL;

        setEnergy(parseFloat(currentEnergy.toFixed(1)));

        const diffKCAL = currentEnergy - requiredEnergy;
        const diffPercent = (diffKCAL / requiredEnergy) * 100;

        setDifferenceKCAL(parseFloat(diffKCAL.toFixed(1)));
        setDifferencePercent(parseFloat(diffPercent.toFixed(1)));

        if (currentEnergy !== 0.0) {
          setPsPercent(
            parseFloat(((currentPsKCAL / currentEnergy) * 100).toFixed(1))
          );
          setHcPercent(parseFloat(((hcKCAL / currentEnergy) * 100).toFixed(1)));
          setLpPercent(parseFloat(((lpKCAL / currentEnergy) * 100).toFixed(1)));
        }

        setPsGKG(currentPsGKG.toString());
        setPsG(parseFloat(currentPsG.toFixed(0)));
        setPsKCAL(parseFloat(currentPsKCAL.toFixed(0)));
      }
    } else {
      setPsGKG("");
      setPsPercent(0.0);
      setHcPercent(0.0);
      setLpPercent(0.0);
      setEnergy(0.0);
      setDifferenceKCAL(0.0);
      setDifferencePercent(0.0);
      setPsG(0.0);
      setPsKCAL(0.0);
    }
  };

  const onChangeHc = (val: any) => {
    if (val !== "") {
      if (val === "0" || val === "0." || val === ".") {
        setHcGKG("0");
        setEnergy(0.0);
        setDifferenceKCAL(0.0);
        setDifferencePercent(0.0);
        setPsPercent(0.0);
        setHcPercent(0.0);
        setLpPercent(0.0);
        setHcG(0.0);
        setHcKCAL(0.0);
      } else {
        const value = parseFloat(val);
        const currentHcGKG = value;
        const currentHcG = value * parseFloat(userActualWeigth);
        const currentHcKCAL = Math.round(currentHcG * 4.0);
        const currentEnergy = psKCAL + currentHcKCAL + lpKCAL;

        setEnergy(parseFloat(currentEnergy.toFixed(1)));

        const diffKCAL = currentEnergy - requiredEnergy;
        const diffPercent = (diffKCAL / requiredEnergy) * 100;

        setDifferenceKCAL(parseFloat(diffKCAL.toFixed(1)));
        setDifferencePercent(parseFloat(diffPercent.toFixed(1)));

        if (currentEnergy !== 0.0) {
          setPsPercent(parseFloat(((psKCAL / currentEnergy) * 100).toFixed(1)));
          setHcPercent(
            parseFloat(((currentHcKCAL / currentEnergy) * 100).toFixed(1))
          );
          setLpPercent(parseFloat(((lpKCAL / currentEnergy) * 100).toFixed(1)));
        }

        setHcGKG(currentHcGKG.toString());
        setHcG(parseFloat(currentHcG.toFixed(0)));
        setHcKCAL(parseFloat(currentHcKCAL.toFixed(0)));
      }
    } else {
      setHcGKG("");
      setEnergy(0.0);
      setDifferenceKCAL(0.0);
      setDifferencePercent(0.0);
      setPsPercent(0.0);
      setHcPercent(0.0);
      setLpPercent(0.0);
      setHcG(0.0);
      setHcKCAL(0.0);
    }
  };

  const onChangeLP = (val: any) => {
    if (val !== "") {
      if (val === "0" || val === "0." || val === ".") {
        setLpGKG("0");
        setEnergy(0.0);
        setDifferenceKCAL(0.0);
        setDifferencePercent(0.0);
        setPsPercent(0.0);
        setHcPercent(0.0);
        setLpPercent(0.0);
        setLpG(0.0);
        setLpKCAL(0.0);
      } else {
        const value = parseFloat(val);

        const currentLpGKG = value;
        const currentLpG = value * parseFloat(userActualWeigth);
        const currentLpKCAL = Math.round(currentLpG * 9.0);
        const currentEnergy = psKCAL + hcKCAL + currentLpKCAL;

        setEnergy(parseFloat(currentEnergy.toFixed(1)));

        const diffKCAL = currentEnergy - requiredEnergy;
        const diffPercent = (diffKCAL / requiredEnergy) * 100;

        setDifferenceKCAL(parseFloat(diffKCAL.toFixed(1)));
        setDifferencePercent(parseFloat(diffPercent.toFixed(1)));

        if (currentEnergy !== 0.0) {
          setPsPercent(parseFloat(((psKCAL / currentEnergy) * 100).toFixed(1)));
          setHcPercent(parseFloat(((hcKCAL / currentEnergy) * 100).toFixed(1)));
          setLpPercent(
            parseFloat(((currentLpKCAL / currentEnergy) * 100).toFixed(1))
          );
        }

        setLpGKG(currentLpGKG.toString());
        setLpG(parseFloat(currentLpG.toFixed(0)));
        setLpKCAL(parseFloat(currentLpKCAL.toFixed(0)));
      }
    } else {
      setLpGKG("");
      setEnergy(0.0);
      setDifferenceKCAL(0.0);
      setDifferencePercent(0.0);
      setPsPercent(0.0);
      setHcPercent(0.0);
      setLpPercent(0.0);
      setLpG(0.0);
      setLpKCAL(0.0);
    }
  };

  const onChangeObjetiveWeigth = (currentWeight: string) => {
    if (currentWeight === "" || currentWeight !== "0") {
      setUserObjetiveWeigth("0.0");
    }
    const weight = parseFloat(currentWeight);
    const size = parseFloat(userHeight) / 100;

    const imc = weight / (size * size);

    setUserObjetiveWeigth(currentWeight);
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <Grid marginTop={3}>
          <Stepper activeStep={activeStep} nonLinear alternativeLabel>
            <Step onClick={() => handleStepChange(0)}>
              <StepLabel>Requerimiento energético</StepLabel>
            </Step>
            <Step onClick={() => handleStepChange(1)}>
              <StepLabel>Actividades</StepLabel>
            </Step>
            <Step onClick={() => handleStepChange(2)}>
              <StepLabel>Distribución de Macronutrientes</StepLabel>
            </Step>
          </Stepper>
        </Grid>
        {activeStep === 0 && (
          <>
            <MyCard>
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Requerimiento energetico"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="Nombre del socio"
                    value={userName}
                    type="search"
                    id="userName"
                    name="userName"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Edad"
                    value={userAge}
                    type="number"
                    id="userAge"
                    name="userAge"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Sexo"
                    value={userGender}
                    type="text"
                    id="userGender"
                    name="userGender"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Estatura"
                    value={userHeight}
                    type="number"
                    id="userId"
                    name="userId"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="% AF"
                    value={userAF}
                    onChange={(e) => setUserAF(e.target.value.toString())}
                    type="number"
                    id="userAF"
                    name="userAF"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Peso actual"
                    value={userActualWeigth}
                    type="number"
                    id="userActualWeigth"
                    name="userActualWeigth"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Peso Objetivo"
                    value={userObjetiveWeigth}
                    onChange={(e) => onChangeObjetiveWeigth(e.target.value)}
                    type="number"
                    id="userObjetiveWeigth"
                    name="userObjetiveWeigth"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="IMC"
                    value={userIMC}
                    type="number"
                    id="userIMC"
                    name="userIMC"
                    fullWidth
                    readOnly
                  />
                </Grid>
                <Grid item xs={3}>
                  <MyTextField
                    label="Porcentaje de accion"
                    value={actionPercent}
                    onChange={(e) =>
                      setActionPercent(e.target.value.toString())
                    }
                    type="number"
                    id="actionPercent"
                    name="actionPercent"
                    hasInputAdornment
                    inputAdornmentText="%"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <MySelect
                    title="Formula"
                    placeholder="Selecciona una opción"
                    items={formulas}
                    value={selectedFormula}
                    onChange={(value) => setSelectedFormula(value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <MySelect
                    title="Objetivo"
                    placeholder="Selecciona una opción"
                    items={objectives}
                    value={selectedObjective}
                    onChange={(value) => setSelectedObjective(value)}
                  />
                </Grid>
              </Grid>
            </MyCard>
          </>
        )}
        {activeStep === 1 && (
          <>
            <MyCard>
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Agregar actividad"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyAutoCompleteInput
                    label="Nombre de actividad"
                    items={activities}
                    value={selectedActivity}
                    onChange={(value) => activitySelected(value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="Energia"
                    value={userMetActivityEnergy}
                    type="number"
                    id="userMetActivityEnergy"
                    name="userMetActivityEnergy"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label="Tiempo (Minutos)"
                    placeholder="60"
                    value={userMetActivityTime}
                    onChange={(e) =>
                      setUserMetActivityTime(e.target.value.toString())
                    }
                    type="number"
                    id="userMetActivityTime"
                    name="userMetActivityTime"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <CardActions
                sx={{ justifyContent: "flex-end" }}
                style={{ paddingRight: "0px" }}
              >
                <Grid item>
                  <MyButton
                    handleClick={createNewActivity}
                    title="Agregar a la lista"
                    color="primary"
                  />
                </Grid>
              </CardActions>

              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Lista de actividades"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <List>
                  {userMetActivityList.map((item, index) => {
                    return (
                      <ListItem key={index}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item xs={4}>
                            <MyTitle
                              title={item.label}
                              variant="body1"
                              align="left"
                              color="dark"
                              noWrap={false}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <MyTitle
                              title={`Energia MET: ${item.energyMET}`}
                              variant="body1"
                              align="center"
                              color="dark"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <MyTitle
                              title={`Energia: ${item.met}`}
                              variant="body1"
                              align="center"
                              color="dark"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <MyTitle
                              title={`${item.time} minutos`}
                              variant="body1"
                              align="center"
                              color="dark"
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <MyButton
                              title="Eliminar"
                              color="error"
                              size="small"
                              fullWidth
                              handleClick={() => removeActivity(item.id)}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </MyCard>
          </>
        )}

        {activeStep === 2 && (
          <>
            <MyCard>
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <MyTitle
                    title="Distribución de Macronutrientes y Suplementos"
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                </Grid>
                {warningCalculatorResponse !== "" && (
                  <Grid item xs={12}>
                    <MyTitle
                      title={warningCalculatorResponse}
                      variant="h6"
                      align="left"
                      color="error"
                      gutterBottom
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <MyTextField
                    label="GEB"
                    type="number"
                    fullWidth
                    value={resultGEB}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="GET"
                    type="number"
                    fullWidth
                    value={resultGET}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Energia requerida"
                    type="number"
                    fullWidth
                    value={requiredEnergy}
                    readOnly
                  />
                </Grid>
                {/* */}
                <Grid item xs={4}>
                  <MyTextField
                    label="PS - g/kg"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="PS g/kg"
                    fullWidth
                    value={psGKG}
                    onChange={(e) => onChangePs(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="HC - g/kg"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="HC g/kg"
                    fullWidth
                    value={hcGKG}
                    onChange={(e) => onChangeHc(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="LP - g/kg"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="LP g/kg"
                    fullWidth
                    value={lpGKG}
                    onChange={(e) => onChangeLP(e.target.value)}
                  />
                </Grid>
                {/* */}
                <Grid item xs={12}>
                  <MyTitle
                    title="Resultados"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="PS - %"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="PS %"
                    fullWidth
                    value={psPercent}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="HC %"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="HC %"
                    fullWidth
                    value={hcPercent}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="LP - %"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="LP %"
                    fullWidth
                    value={lpPercent}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="PS - kcal"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="PS kcal"
                    fullWidth
                    value={psKCAL}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="HC - kcal"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="HC kcal"
                    fullWidth
                    value={hcKCAL}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="LP - kcal"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="LP kcal"
                    fullWidth
                    value={lpKCAL}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="PS - g"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="PS g"
                    fullWidth
                    value={psG}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="HC - g"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="HC g"
                    fullWidth
                    value={hcG}
                    readOnly
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="LP - g"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="LP g"
                    fullWidth
                    value={lpG}
                    readOnly
                  />
                </Grid>
                {/* end ps, hc and lp */}
                <Grid item xs={4}>
                  <MyTextField
                    label="Energia"
                    type="number"
                    fullWidth
                    readOnly
                    value={energy}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Diferencia"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="%"
                    fullWidth
                    value={differencePercent}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextField
                    label="Diferencia"
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="kcal"
                    fullWidth
                    value={differenceKCAL}
                  />
                </Grid>
              </Grid>
            </MyCard>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <MyButton
              title="Anterior"
              color="primary"
              handleClick={handlePrevious}
            />
          </div>
          <div>
            {activeStep + 1 === totalSteps ? (
              warningCalculatorResponse === "" ? (
                <div>
                  <MyButton
                    title="Guardar y subir documentos"
                    color="primary"
                    handleClick={goToPhotoEvidence}
                  />
                </div>
              ) : null
            ) : (
              <MyButton
                title="Siguiente"
                color="primary"
                handleClick={handleNext}
              />
            )}
          </div>
        </div>
      </Container>
      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message={alertText}
        closeText="Cerrar"
        handleClose={cancelAlertHandle}
      />
    </>
  );
};

export default Calculator;
