import { useState, useRef, useCallback, useEffect, useContext } from 'react';
import Webcam from 'react-webcam';
import { Button, Container, Grid, Step, StepLabel, Stepper, CardContent, CardActions } from "@mui/material";
import { MyTitle, MyLoading, MyButton, MySelect, MyAlert, MyDialog, MyModal, MyCard } from '../../components/Generic/Index';
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import AddPhotoAlternateTwoToneIcon from '@mui/icons-material/AddPhotoAlternateTwoTone';
import { GetConsultPhotoTypeService } from "../../services/Nutrition/ConsultPhotoType";
import { CreateUserConsultPhotoService } from "../../services/Nutrition/CreateUserConsultPhoto";
import { UserInfoService } from "../../services/User";
import { AppContext } from "../../data/state";
import { navigateWithScroll } from "../../functions/navigateUtils";
import "./PhotoEvidence.css";
import server from '../../api/server';

const WebcamComponent = () => <Webcam />

var pictures = new Map();

const PhotoEvidence: React.FC = () => {
  const { state } = useContext(AppContext);

  const location: any = useLocation();
  const navigate = useNavigate();

  const token = state.token;
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 4;

  const [isActive, setIsActive] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentOpenPhoto, setCurrentOpenPhoto] = useState("");
  const [currentBodyPart, setCurrentBodyPart] = useState("");

  const [userName, setUserName] = useState("");
  const [nutritionConsultID, setNutritionConsultID] = useState(0);
  const [userId, setUserId] = useState(0);
  const [bodyPhotoTypes, setBodyPhotoTypes] = useState<any[]>([]);

  const [pictureSelected, setPictureSelected] = useState('');
  const [takePicButton, setTakePicButton] = useState(false);
  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: "user",
    deviceId: ""
  });

  const [deviceName, setDeviceName] = useState("");
  const [devices, setDevices] = useState([]);

  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogMessageText, setDialogMessageText] = useState("");

  const handleDevices = useCallback(
    (mediaDevices: any) => {
      mediaDevices = mediaDevices.filter((item: any) => item.kind === "videoinput")
      const newObj = mediaDevices.map((item: any) => {
        return {
          id: item.deviceId,
          label: item.label,
        };
      });
      setDevices(current => { return newObj });
    },
    [setDevices]
  );

  const webcamRef = useRef<any>(null);

  const capture = useCallback(
    (pictureSelected: any) => {
      const imageSrc = webcamRef.current.getScreenshot();
      pictures.set(pictureSelected, {
        ...pictures.get(pictureSelected),
        photo: imageSrc
      });
    },
    [webcamRef]
  );

  useEffect(() => {
    (async () => {
      if (location.state && location.state.consultId) {
        setNutritionConsultID(location.state.consultId);
        setUserId(location.state.userId);
        await requestUserInfo(location.state.userId);
      }

      await requestPhotoType();
      await loadDevices();
    })();
  }, []);

  const requestUserInfo = async (id: any) => {
    setIsActive(true);
    try {
      const userInfo = await UserInfoService.getUser(token, id);

      setUserName(userInfo.name);
      setUserId(userInfo.id);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const requestPhotoType = async () => {
    setIsActive(true);
    try {
      const photoTypes = await GetConsultPhotoTypeService.getPhotoTypes(token);

      photoTypes.forEach((element: any) => {
        if (element.active === true) {
          pictures.set(element.photoType, {
            id: element.id,
            photo: ""
          });
        }
      });

      setBodyPhotoTypes(photoTypes);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const loadDevices = async () => {
    await navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then(() => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      })
      .catch((err) => {
        /* handle the error */
      });
  };

  const enableWC = () => {
    setTakePicButton(true);
  };

  const takePic = (pictureSelected: any) => {
    setPictureSelected(current => {
      return pictureSelected
    });

    capture(pictureSelected);
    setTakePicButton(false);
  };

  const onSelectCamera = (camera: any) => {
    setVideoConstraints(params => ({
      ...params,             // Reuse the previous properties
      deviceId: camera, // Overwrite the new ones
    }));

    setDeviceName(current => { return camera });
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handlePrevious = () => {
    const previousStep = activeStep - 1;
    if (previousStep >= 0) {
      handleStepChange(previousStep);
    }
  };

  const removePrefix = (str: string) => {
    let result = str.slice(str.indexOf(',') + 1);
    return result;
  };

  const submitPictures = async () => {
    setIsActive(true);

    if (pictures.size !== 0) {
      Array.from(pictures.entries()).map(([key, val]) => {
        if (val.photo !== "") {
          const data = {
            nutritionConsultID: nutritionConsultID,
            nutritionPhotoTypeID: val.id,
            photo: removePrefix(val.photo)
          };

          (async () => {
            try {
              const userPhotos = await CreateUserConsultPhotoService.createConsultPhoto(token, data);

            } catch (error: any) {
              setIsActive(false);
              setDialogTitleText("Error al guardar las fotografías");
              setDialogMessageText("Favor de revisar los datos ingresados.");
              setIsDialogOpen(true);
              console.log(error);
              return;
            }
          })();
        }
      })

      setIsActive(true);
      try {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        };
        const responseServer = await server.put(
          `staff/nutrition-consult/complete-photos/${nutritionConsultID}`,
          JSON.stringify(""),
          {headers}
        );
        setIsActive(false);
        setDialogTitleText("Evaluación guardada correctamente");
        setDialogMessageText("¿Desea ir al apartado de resultados?");
        setIsAlertOpen(true);
        setIsDialogOpen(true);
      } catch (error: any) {
        console.log(error);
        setDialogTitleText("Error al guardar las fotografías");
        setDialogMessageText("Favor de revisar los datos ingresados.");
        setIsDialogOpen(true);
        setIsActive(false);
      }
    } else {
      setDialogTitleText("Error al guardar las fotografías");
      setDialogMessageText("Favor de revisar los datos ingresados.");
      setIsDialogOpen(true);
      setIsActive(false);
    }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < totalSteps) {
      handleStepChange(nextStep);
    }
  };

  const savePicture = async () => {
    //handle save picture and go to next step
    handleNext();
  };

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChangePhoto = async (e: any, body: string) => {
    setIsActive(true);
    let file = e.target.files[0];

    if (file) {
      let binaryString = await convertToBase64(file);

      await pictures.set(body, {
        ...pictures.get(body),
        photo: binaryString as string
      });
    }

    setTakePicButton(false);
    setIsActive(false);
  };

  const openImage = (photoName: any) => {
    setIsModalOpen(true);
    setCurrentBodyPart(photoName);
    setCurrentOpenPhoto((prev) => { return pictures.get(photoName).photo });
  };

  const cancelAlertHandle = () => {
    setIsAlertOpen(false);
  };

  const cancelDialoghandle = () => {
    setIsAlertOpen(false);
    navigateWithScroll(navigate, '/nutrition');
  };

  const cancelModalHandle = () => {
    setIsModalOpen(false);
    setCurrentOpenPhoto("");
    setCurrentBodyPart("");
  };

  const confirmDialoghandle = () => {
    setIsAlertOpen(false);
    navigateWithScroll(navigate, '/nutrition/results', {
      state: {
        id: nutritionConsultID,
        userId: userId ,
        readOnly: false,
      }
    });

  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <Grid marginTop={3}>
          <Stepper activeStep={activeStep} nonLinear alternativeLabel>
            {
              bodyPhotoTypes.map((item, index) => {
                return (
                  <Step key={index}>
                    <StepLabel key={index} onClick={() => handleStepChange(index)}>{item.photoType}</StepLabel>
                  </Step>
                );
              })
            }
          </Stepper>
        </Grid>

        <MyCard >
          <Grid container spacing={2} marginBottom={2} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <MyTitle
                title={userName}
                variant="h5"
                align="left"
                color="primary"
                noWrap
              />
            </Grid>
            <Grid item xs={2}>
              <MyTitle
                title={userId.toString()}
                variant="h5"
                align="left"
                color="primary"
                noWrap
              />
            </Grid>
            <Grid item xs={8}>
              <MyTitle
                title="Adjunta las fotografías del cuestionario de nutrición. Tambien puedes usar webcam, si conectas alguna via USB asegurate de recargar la pagina y seleccionarla."
                variant="body1"
                align="justify"
                color="dark"
                gutterBottom
              />
            </Grid>
            <Grid item xs={4}>
              <MySelect
                title="Camaras disponibles"
                placeholder="Selecciona una opción:"
                items={devices}
                value={deviceName}
                onChange={(value) => onSelectCamera(value)}
              />
            </Grid>
          </Grid>
          <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2} padding={6} >
            {
              takePicButton ? (
                <Grid item xs={8} style={{ textAlign: "center" }} >
                  <Webcam
                    audio={false}
                    width={480}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                  />
                </Grid>
              ) : (
                <Grid item xs={8} style={{ textAlign: "center" }} >
                  <CameraAltTwoToneIcon style={{ fontSize: "245px" }} />
                </Grid>
              )
            }
            {
              bodyPhotoTypes.map((item, index) => {
                if (activeStep === index) {
                  return (
                    <Grid key={index} item xs={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} gap={2}>
                      <Button variant="outlined" component="label" key={index}>
                        Subir archivo
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => onChangePhoto(e, item.photoType)}
                          autoComplete="off"
                          key={`${item.photoTy}-input`}
                        />
                      </Button>
                      {
                        takePicButton ?
                          <Button variant="outlined" component="label" fullWidth onClick={(e) => takePic(item.photoType)}>Tomar foto</Button>
                          :
                          <Button variant="outlined" component="label" fullWidth onClick={enableWC} >Usar webcam</Button>
                      }
                    </Grid>
                  );
                }
              })
            }
          </Grid>
          <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2} >
            {
              Array.from(pictures.entries()).map(([key, val]) => {
                return (
                  <Grid item xs={3} direction="column" alignItems="center" display={"flex"} justifyContent={"center"} key={key} >
                    <MyTitle
                      title={key}
                      variant="body2"
                      align="justify"
                      color="dark"
                      gutterBottom
                    />
                    {val.photo !== "" ? (
                      <img className="card-image" src={val.photo} width={200} alt={`photography-${key}`} key={key} onClick={() => openImage(key)} />
                    ) : (
                      <AddPhotoAlternateTwoToneIcon />
                    )}
                  </Grid>
                );
              })
            }
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </MyCard>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <MyButton
              title="Anterior"
              color="primary"
              handleClick={handlePrevious}
            />
          </div>
          <div>
            {
              activeStep + 1 === totalSteps ?
                <MyButton
                  title="Finalizar"
                  color="primary"
                  handleClick={submitPictures}
                />
                : <MyButton
                  title="Siguiente"
                  color="primary"
                  handleClick={handleNext}
                />
            }
          </div>
        </div>
      </Container>
      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message="Evaluación guardada correctamente."
        closeText="Cerrar"
        handleClose={cancelAlertHandle}
      />
      <MyDialog
        open={isDialogOpen}
        title={dialogTitleText}
        message={dialogMessageText}
        cancelTextButton="Volver al buscador"
        confirmTextButton="Aceptar"
        cancelHandle={cancelDialoghandle}
        confirmHandle={confirmDialoghandle}
      />
      <MyModal open={isModalOpen} title={currentBodyPart} handleClose={cancelModalHandle} optionalWidth={800}>
        <CardContent className="card-content-modal" >
          <img className="card-image-modal" src={currentOpenPhoto} alt={`photography-${"key"}`} key={"key"} />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <MyButton
            handleClick={cancelModalHandle}
            title="Cerrar"
            color="primary"
            variant="text"
            size="small"
          />
        </CardActions>
      </MyModal>
    </>
  );
};

export default PhotoEvidence;
