import server from '../../api/server';

export class NewConsultService {
    static async createConsult(token: string, data: any): Promise<any> {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        };

        try {
            const responseServer = await server.post(
              `staff/nutrition-consult`,
              JSON.stringify(data),
              {headers}
            );

            return responseServer.data.data;
        } catch (error: any) {
            throw new Error(error.response.status);
        }
    }
}
