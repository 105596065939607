import React from "react";
import { CircularProgress, Stack } from "@mui/material";

interface MyLoadingProps {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  size?: number,
  thickness?: number,
  variant?: 'determinate' | 'indeterminate',
};

const MyLoading: React.FC<MyLoadingProps> = ({
  color = 'primary',
  size = 40,
  thickness = 3.6,
  variant = 'indeterminate'
}) => {

  return (

    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '1'
    }}
    >
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <CircularProgress
          color={color}
          size={size}
          thickness={thickness}
          variant={variant}
        />
      </Stack>
    </div>
  )
};

export default MyLoading;
