import server from '../../../api/server';

export class GetUserHistoryService {
  static async getHistory(id: any, token: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${token}`
    };

    try {
      const responseServer = await server.get(
        `staff/nutrition-consult/history/${id}`,
        { headers }
      );
      return responseServer.data.data;
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  }
}
