import React from 'react';
import { Navigate } from 'react-router-dom';
import { MyLoading } from '../Index';

interface ProRoutesProps {
  component: any,
  permissions: any,
  pathGroup: number,
};

const ProtectedRoutes: React.FC<ProRoutesProps> = ({
  component,
  permissions,
  pathGroup = 0
}) => {
  return (
    permissions && permissions.includes(pathGroup) ?
      <React.Suspense fallback={<MyLoading />}>
        {component}
      </React.Suspense>
    : <Navigate to="/" replace />
  );
};

export default ProtectedRoutes;
