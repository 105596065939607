import Home from "../views/Home/Home";

import Training from "../views/Training";
import Routine from "../views/Training/CreateRoutine";
import RoutineTwo from "../views/Training/RoutineTwo";
import RoutineResume from "../views/Training/RoutineResume";
import RoutineDetail from "../views/Training/RoutineDetail";

import Nutrition from "../views/Nutrition/Nutrition";
import AnthropometricAssessment from "../views/Nutrition/AnthropometricEvaluation";
import Bioimpedance from "../views/Nutrition/Bioimpedance";
import Results from "../views/Nutrition/Results";
import PhotoEvidence from "../views/Nutrition/PhotoEvidence";
import FileUpload from "../views/Nutrition/FileUpload";
import Calculator from "../views/Nutrition/Calculator";
import MedicalHistory from "../views/Nutrition/MedicalHistory";
import Historical from "../views/Nutrition/Historical";

import Users from "../views/Users/Users";

import HomeIcon from '@mui/icons-material/Home';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import LocalDiningOutlinedIcon from '@mui/icons-material/LocalDiningOutlined';
import NutritionReport from "../views/Reports/NutritionReport";
import PhonelinkEraseOutlinedIcon from '@mui/icons-material/PhonelinkEraseOutlined';

const RouterPath = [
  {
    path: '/',
    label: 'Inicio',
    element: <Home />,
    isPrivate: true,
    icon: <HomeIcon />,
    id: 0
  },
  {
    path: '/training',
    label: 'Training',
    element: <Training />,
    isPrivate: true,
    icon: <FitnessCenterIcon />,
    id: 1
  },
  {
    path: '/routine',
    label: 'Crear rutina',
    element: <Routine />,
    isPrivate: true,
    icon: <SportsMartialArtsIcon />,
    id: 1
  },
  {
    path: '/routine-2',
    label: 'Routine 2',
    element: <RoutineTwo />,
    isPrivate: true,
    icon: <SportsMartialArtsIcon />,
    id: 1
  },
  {
    path: '/routine-resume',
    label: 'Routine resume',
    element: <RoutineResume />,
    isPrivate: true,
    icon: <SportsMartialArtsIcon />,
    id: 1
  },
  {
    path: '/routine-detail',
    label: 'Routine detail',
    element: <RoutineDetail />,
    isPrivate: true,
    icon: <SportsMartialArtsIcon />,
    id: 1
  },
  {
    path: '/nutrition',
    label: 'Historial de consultas',
    element: <Nutrition />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/medical-history',
    label: 'Historia clinica',
    element: <MedicalHistory />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/anthropometric',
    label: 'Evaluación antropométrica',
    element: <AnthropometricAssessment />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/menu',
    label: 'Menu',
    element: <AnthropometricAssessment />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/equivalences',
    label: 'Equivalencias',
    element: <AnthropometricAssessment />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/bioimpedance',
    label: 'Bioimpedancia',
    element: <Bioimpedance />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/results',
    label: 'Resultados',
    element: <Results />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/evidence',
    label: 'Evidencia fotográfica',
    element: <PhotoEvidence />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/fileUpload',
    label: 'Carga de archivos',
    element: <FileUpload />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/calculator',
    label: 'Calculadora',
    element: <Calculator />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/report',
    label: 'Graficas',
    element: <NutritionReport />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/nutrition/historical',
    label: 'Historial de progreso',
    element: <Historical />,
    isPrivate: true,
    icon: <LocalDiningOutlinedIcon />,
    id: 2
  },
  {
    path: '/users',
    label: 'Socios',
    element: <Users />,
    isPrivate: true,
    icon: <PhonelinkEraseOutlinedIcon />,
    id: 3
  }
]

export { RouterPath };
