import { useState, useContext } from "react";
import {
  CardActions,
  CardContent,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  MyButton,
  MyCard,
  MyTextField,
  MyTitle,
  MyItem,
  MyItemContainer,
  MyModal,
  MyAlert,
  MyLoading,
} from "../../components/Generic/Index";
import { SmartphoneTwoTone } from "@mui/icons-material";
import { UserInfoLocalService } from "../../services/UserLocalDb";
import { UserUnlinkService } from "../../services/UnlinkUser";
import { AppContext } from "../../data/state";
import moment from "moment";

const Users: React.FC = () => {
  const { state } = useContext(AppContext);
  const token = state.token;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userBirthDate, setUserBirthDate] = useState("");
  const [userId, setUserId] = useState("");
  const [userFoundId, setUserFoundId] = useState("");
  const [userNotFoundId, setUserNotFoundId] = useState("");

  const formatDate = (date: any) => moment(date).format("DD-MM-YYYY");

  const searchUser = async () => {
    setIsActive(true);
    try {
      const userInfo = await UserInfoLocalService.getUser(token, userId);

      setUserName(userInfo.name);
      setUserEmail(userInfo.email);
      setUserPhoneNumber(userInfo.phoneNumber);
      setUserGender(userInfo.gender);
      setUserBirthDate(formatDate(userInfo.birthDate));
      setUserFoundId(userInfo.id);
      setUserNotFoundId("");
      setIsActive(false);
    } catch (error: any) {
      setUserFoundId("");
      setUserNotFoundId(userId);
      setIsActive(false);
      console.log(error);
    }
  };

  const handleUnlinkDevice = async () => {
    //unlink device from user logic
    setIsModalOpen(false);
    setIsActive(true);
    try {
      await UserUnlinkService.unlinkUserDevice(token, userFoundId);

      setUserFoundId("");
      setUserId("");
      setUserNotFoundId("");
      setIsActive(false);
      setIsAlertOpen(true);
    } catch (error: any) {
      setUserFoundId("");
      setIsActive(false);
      console.log(error);
    }
  };

  const onChangeUserId = (evt: any) => {
    const id = evt.target.validity.valid ? evt.target.value : userId;
    setUserId(id);
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <MyCard>
          <CardContent>
            <MyItemContainer>
              <MyItem>
                <MyTitle
                  title="Buscar socio"
                  variant="h5"
                  align="left"
                  gutterBottom
                  noWrap
                />
              </MyItem>
              <MyItem xs={12} md={9}>
                <MyTextField
                  value={userId}
                  onChange={(e) => onChangeUserId(e)}
                  id="userId"
                  label="Número de socio"
                  type="search"
                  name="userId"
                  pattern="[0-9]*"
                  fullWidth
                />
              </MyItem>
              <MyItem xs={12} md={3}>
                <MyButton
                  title="Buscar"
                  color="primary"
                  size="medium"
                  handleClick={searchUser}
                  disabled={!userId}
                  fullWidth
                />
              </MyItem>
            </MyItemContainer>
          </CardContent>
        </MyCard>
        <MyCard>
          <CardContent>
            <MyItemContainer>
              <MyItem>
                <MyTitle
                  title="Información general"
                  variant="h5"
                  align="left"
                  gutterBottom
                  noWrap
                />
              </MyItem>
              {userFoundId !== "" ? (
                <>
                  <MyItem>
                    <MyTitle
                      title="Basicos"
                      variant="subtitle1"
                      align="left"
                      noWrap
                    />
                  </MyItem>
                  <MyItem xs={12} md={6}>
                    <MyTextField label="Nombre" fullWidth value={userName} />
                  </MyItem>
                  <MyItem xs={12} md={6}>
                    <MyTextField label="Correo" fullWidth value={userEmail} />
                  </MyItem>
                  <MyItem xs={12} md={4}>
                    <MyTextField
                      label="Numero de contacto"
                      fullWidth
                      value={userPhoneNumber}
                    />
                  </MyItem>
                  <MyItem xs={12} md={4}>
                    <MyTextField label="Sexo" fullWidth value={userGender} />
                  </MyItem>
                  <MyItem xs={12} md={4}>
                    <MyTextField
                      label="Fecha de nacimiento"
                      fullWidth
                      value={userBirthDate}
                    />
                  </MyItem>
                  {userFoundId !== "" && (
                    <MyItem>
                      <MyButton
                        title="Desvincular"
                        size="medium"
                        handleClick={() => setIsModalOpen(true)}
                      />
                    </MyItem>
                  )}
                </>
              ) : (
                userNotFoundId !== "" && (
                  <MyItem>
                    <MyTitle
                      title={`Socio con id "${userNotFoundId}" no encontrado.`}
                      variant="subtitle1"
                      color="info"
                      align="center"
                      noWrap
                    />
                  </MyItem>
                )
              )}
            </MyItemContainer>
          </CardContent>
        </MyCard>
      </Container>

      <MyModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Desvincular dispositivo"
      >
        <CardContent>
          <MyTitle
            title="Solo se permite un único dispositivo vinculado en este momento.
        Esta acción cerrará la sesión actual y deberá iniciar sesión en el nuevo dispositivo."
            variant="body1"
            align="justify"
            color="dark"
            gutterBottom
          />

          <List>
            <ListItemButton>
              <ListItemIcon>
                <SmartphoneTwoTone color="primary" />
              </ListItemIcon>
              <ListItemText>
                <MyTitle
                  title="Device Id"
                  variant="body1"
                  align="justify"
                  color="dark"
                />
                <MyTitle
                  title="Última Desvinculación: 31/12/2023."
                  variant="body1"
                  align="justify"
                  color="dark"
                />
              </ListItemText>
            </ListItemButton>
          </List>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <MyButton
            handleClick={() => setIsModalOpen(false)}
            title="Cancelar"
            color="primary"
            variant="text"
            size="small"
          />
          <MyButton
            handleClick={() => handleUnlinkDevice()}
            title="Aceptar"
            color="error"
            variant="text"
            size="small"
          />
        </CardActions>
      </MyModal>
      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message="Dispositivo desvinculado correctamente"
        closeText="Cerrar"
        handleClose={() => setIsAlertOpen(false)}
      />
    </>
  );
};

export default Users;
