import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

interface TableColumn {
  id: string;
  label: string;
  // Puedes agregar más propiedades según tus necesidades
}

interface TableDataRow {
  [key: string]: any;
}

interface HistoricalProps {
  headers?: TableColumn[];
  data?: TableDataRow[];
}

const MyTable: React.FC<HistoricalProps> = ({ headers, data }) => {
  return (
    <TableContainer >
      <Table>
        <TableBody >
          {
            data && Object.entries(data as any).map(([key, value], index) => (
              <TableRow key={index}>
                <TableCell
                  style={{ textAlign: "center" }}
                  key={key}
                  children={key}
                />
                {
                  Object.entries(value as any).map(([k, val]) => (
                    <TableCell
                      style={{ textAlign: "center" }}
                      key={(k as any)}
                      children={(val as any)}
                    />
                  ))
                }
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyTable;
