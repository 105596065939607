import React from "react";
import { Box, Card, CardHeader, Modal } from "@mui/material";
import MyTitle from "../MyTitle/MyTitle";

interface MyModalProps {
  open?: boolean;
  title?: string;
  children?: any;
  handleClose?: () => void;
  optionalWidth?: number;
}

const modalStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};

const cardStyle = {
  width: 400,
  maxWidth: "90%",
  borderRadius: "12px",
};

const itemStyle = {
  marginBottom: "16px", // Espacio vertical entre los elementos
};

const MyModal: React.FC<MyModalProps> = ({
  open = false,
  title = "Placeholder",
  children,
  handleClose,
  optionalWidth,
}) => {
  if (optionalWidth !== undefined) {
    cardStyle.width = optionalWidth;
  }

  return (
    <Modal
      open={open}
      //slotProps={{ root: { onClick: handleClose } }}
      onClose={handleClose}
    >
      <Box sx={modalStyle}>
        <Card sx={cardStyle}>
          <CardHeader
            title={
              <MyTitle
                title={title}
                variant="h6"
                color="primary"
                align="left"
                gutterBottom
                noWrap
              />
            }
          ></CardHeader>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {React.Children.map(children, (child) => (
              <div style={itemStyle}>{child}</div>
            ))}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default MyModal;
