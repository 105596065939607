import React from 'react'
import './MyTitle.css'
import { Typography } from '@mui/material';

interface MITitleProps {
  title?: string;
  variant?: 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2',
  color?: string;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right',
  gutterBottom?: boolean,
  noWrap?: boolean,
};

const MyTitle: React.FC<MITitleProps> = ({
  title = '',
  variant = 'h3',
  color = 'primary',
  align = 'center',
  gutterBottom = false,
  noWrap = false
}) => {

  return (
    <Typography
      variant={variant}
      color={color}
      align={align}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
    >
      {title}
    </Typography>
  )
}

export default MyTitle;

