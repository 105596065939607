import { Grid, ListItem, Skeleton, Typography } from "@mui/material"

const MyListItemSkeleton: React.FC = () => {
  return (
    <>
      <ListItem>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <Skeleton width={55} />
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={65} />
            </Typography>
          </Grid>
        </Grid>
        <Grid justifyContent="space-between" alignItems="center">
          <Skeleton height={45} width={105} />
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <Skeleton width={55} />
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={65} />
            </Typography>
          </Grid>
        </Grid>
        <Grid justifyContent="space-between" alignItems="center">
          <Skeleton height={45} width={105} />
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <Skeleton width={55} />
            </Typography>
            <Typography variant="subtitle2">
              <Skeleton width={65} />
            </Typography>
          </Grid>
        </Grid>
        <Grid justifyContent="space-between" alignItems="center">
          <Skeleton height={45} width={105} />
        </Grid>
      </ListItem>
    </>
  )
};

export default MyListItemSkeleton;