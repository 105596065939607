import React, { ChangeEventHandler } from "react";
import { TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import "./MyTextField.css";

interface MyTextFieldProps {
  id?: string;
  value?: any;
  label?: string;
  variant?: "filled" | "outlined" | "standard";
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  placeholder?: string;
  type?:
    | "button"
    | "checkbox"
    | "date"
    | "email"
    | "file"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "undefined";
  size?: "medium" | "small";
  name?: string;
  multiline?: boolean;
  margin?: "dense" | "none" | "normal";
  fullWidth?: boolean;
  defaultValue?: string;
  initialValue?: string;
  error?: boolean;
  disabled?: boolean;
  hasHelperText?: boolean;
  helperText?: string;
  select?: boolean;
  readOnly?: any;
  hasInputAdornment?: boolean;
  inputAdornmentText?: string;
  pattern?: string;
  minDate?: string;
  onKeyPress?: (e: any) => void;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const MyTextField: React.FC<MyTextFieldProps> = ({
  id,
  variant = "outlined",
  placeholder = "",
  type = "",
  color = "primary",
  name = "",
  label,
  value = "",
  size = "small",
  multiline,
  margin = "none",
  fullWidth,
  defaultValue,
  initialValue,
  minDate,
  disabled,
  hasHelperText,
  helperText = "Placeholder",
  error,
  select,
  readOnly,
  hasInputAdornment,
  inputAdornmentText = "Placeholder",
  onChange,
  onKeyPress,
  pattern,
}) => {
  const getColor = (text: any) => {
    if (text === "Bajo riesgo") {
      return "primary";
    } else if (text === "Alto riesgo") {
      return "error";
    }

    return "secondary";
  };

  const textFieldStyle: React.CSSProperties = {
    borderRadius: "80px",
  };

  return (
    <TextField
      style={textFieldStyle}
      id={id}
      variant={variant}
      label={label}
      placeholder={placeholder}
      type={type}
      color={color}
      size={size}
      value={value}
      name={name}
      multiline={multiline}
      margin={margin}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      disabled={disabled}
      helperText={hasHelperText ? helperText : ""}
      error={error}
      select={select}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        startAdornment: hasInputAdornment ? (
          <InputAdornment position="start">
            <Typography
              variant="body1"
              color={getColor(inputAdornmentText)}
              align="left"
              noWrap
            >
              {inputAdornmentText}
            </Typography>
          </InputAdornment>
        ) : undefined,
        inputProps: { min: minDate },
      }}
      inputProps={{
        readOnly: readOnly ? "readonly" : undefined,
        min: type === "number" ? 0 : "",
        pattern: pattern,
      }}
      onKeyDown={onKeyPress}
    ></TextField>
  );
};

export default MyTextField;
