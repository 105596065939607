import server from '../../api/server';

export class CreateUserConsultDocumentService {
    static async createConsultDocument(token: string, data: any): Promise<any> {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        };

        try {
            const responseServer = await server.post(
              `consult-documents`,
              JSON.stringify(data),
              {headers}
            );

            if (responseServer.data.statusCode != 200) {
                console.log("ERROR");
            }

            return responseServer.data.data;
        } catch (error: any) {
            throw new Error(error.response.status);
        }
    }
}
