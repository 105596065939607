import { Grid, ListItem } from '@mui/material';
import React from 'react';
import { MyButton, MyTitle } from '../Index';

interface MyListItemProps {
  userConsults: Array<{
    sessionNum: number;
    date: string;
    completed: boolean;
    hasPhotos: boolean;
    hasReport: boolean;
    id: string;
  }>;
  completeHandle: (id: string, completed: boolean) => void;
  addEvidence: (id: any) => void;
  seeReport: (id: any) => void;
};

const MyListItem: React.FC<MyListItemProps> = ({ userConsults, addEvidence, completeHandle, seeReport }) => {
  return (
    <div>
      {userConsults.map((item, index) => (
          <ListItem key={index}>
            <Grid item xs={6} container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xs={8}>
                <MyTitle title={`Sesion #${item.sessionNum}`} variant="subtitle1" align="left" color="dark" />
              </Grid>
              <Grid item xs={8}>
                <MyTitle title={item.date} variant="subtitle2" align="left" color="dark" />
              </Grid>
            </Grid>
              <Grid item xs={6} container justifyContent="flex-start" alignItems="center" direction="row">
                {
                  !item.hasPhotos ?
                    <Grid item xs={6}>
                      <MyButton
                        title="Agregar fotos"
                        color="primary"
                        size="small"
                        handleClick={() => addEvidence(item.id)}
                      />
                    </Grid>
                  :
                    <Grid item xs={6}>
                      <MyButton
                        title={item.completed ? "Ver resultados" : "Completar"}
                        color="primary"
                        size="small"
                        handleClick={() => completeHandle(item.id, item.completed)}
                      />
                    </Grid>
                }
                {
                  item.hasReport &&
                    <Grid item xs={6}>
                      <MyButton
                        title="ver reporte grafico"
                        color="primary"
                        size="small"
                        handleClick={() => seeReport(item.id)}
                      />
                  </Grid>
                }
              </Grid>
          </ListItem>
      ))}
    </div>
  );
};

export default MyListItem;
