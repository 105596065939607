import {
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.css";

const Training: React.FC = () => {
  return (
    <>
      <div className="container-app">
        <Grid>
        <Grid marginTop={3}>
            <Typography
              variant="h4"
              component="h4"
              color="primary"
              textAlign={"center"}
            >
              Acondicionamiento
            </Typography>
          </Grid>

          <hr />

          <Card>
            <CardContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Información basica</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="name"
                      label="Nombre"
                      variant="standard"
                      color="primary"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="numberClient"
                      label="No. de socio"
                      variant="standard"
                      color="primary"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="typeTraining-label">
                        Modo de entrenamiento
                      </InputLabel>
                      <Select
                        labelId="typeTraining-label"
                        id="typeTraining"
                        onChange={() => {}}
                        defaultValue={"green"}
                      >
                        <MenuItem value={"green"}>Green</MenuItem>
                        <MenuItem value={"silver"}>Silver</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="coach-label">Entrenador</InputLabel>
                      <Select
                        labelId="coach-label"
                        id="coach"
                        onChange={() => {}}
                        defaultValue={"A"}
                      >
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="objective-label">Objetivo</InputLabel>
                      <Select
                        labelId="objective-label"
                        id="objective"
                        onChange={() => {}}
                        defaultValue={"A"}
                      >
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <TextField
                      id="observations"
                      label="Observaciones"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Cuestionario de salud</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="hasTraining-label">
                        ¿Has entrenado antes?
                      </InputLabel>
                      <Select
                        labelId="hasTraining-label"
                        id="hasTraining"
                        onChange={() => {}}
                        defaultValue={"Si"}
                      >
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="amount-days-training-label">
                        ¿Cuantos dias a la semana vas a dedicar al
                        entrenamiento?
                      </InputLabel>
                      <Select
                        labelId="amount-days-training-label"
                        id="amount-days-training"
                        onChange={() => {}}
                        defaultValue={"A"}
                      >
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="amount-time-training-label">
                        ¿Cuanto tiempo?
                      </InputLabel>
                      <Select
                        labelId="amount-time-training-label"
                        id="amount-time-training"
                        onChange={() => {}}
                        defaultValue={"A"}
                      >
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="pregnancy-label">
                        ¿Estas embarazada?
                      </InputLabel>
                      <Select
                        labelId="pregnancy-label"
                        id="pregnancy"
                        onChange={() => {}}
                        defaultValue={"Si"}
                      >
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="amount-time-pregnancy-label">
                        ¿Cuantas semanas de gestación?
                      </InputLabel>
                      <Select
                        labelId="amount-time-pregnancy-label"
                        id="amount-time-pregnancy"
                        onChange={() => {}}
                        defaultValue={"A"}
                      >
                        <MenuItem value={"A"}>A</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="has-medical-conditions-label">
                        ¿Alguna lesión o enfermedad?
                      </InputLabel>
                      <Select
                        labelId="has-medical-conditions-label"
                        id="has-medical-conditions"
                        onChange={() => {}}
                        defaultValue={"Si"}
                      >
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="medical-conditions"
                      label="¿Cual?"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="has-medical-contraindications-label">
                        ¿Alguna contraindicacion medica?
                      </InputLabel>
                      <Select
                        labelId="has-medical-contraindications-label"
                        id="has-medical-contraindications"
                        onChange={() => {}}
                        defaultValue={"Si"}
                      >
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="medical-conditions-containdications"
                      label="¿Cual?"
                      variant="standard"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Evaluación nutricional</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="has-you-training-label">
                        ¿Has entrenado antes?
                      </InputLabel>
                      <Select
                        labelId="has-you-training-label"
                        id="has-you-training"
                        onChange={() => {}}
                        defaultValue={"Si"}
                      >
                        <MenuItem value={"Si"}>Si</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </>
  );
};

export default Training;
