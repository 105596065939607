import React, { useState, useContext, useEffect } from "react";
import { navigateWithScroll } from "../../../functions/navigateUtils";
import { RouterPath } from "../../../Router/Router";
import { AppContext } from "../../../data/state";
import { menu } from "./SidebarData";
import { MyTitle, MyDialog } from "../Index";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  ListItemButton,
  ClickAwayListener,
  ListItemAvatar,
} from "@mui/material";
import {
  MenuTwoTone,
  AccountCircleTwoTone,
  HomeTwoTone,
  LogoutTwoTone,
  LightModeTwoTone,
  DarkModeTwoTone,
} from "@mui/icons-material";


function ThemeToggle({
  darkMode,
  toggleTheme,
}: {
  darkMode: boolean;
  toggleTheme: () => void;
}) {

  return (

    <ListItemButton onClick={toggleTheme}>
      <ListItemIcon>
        {darkMode ? (
          <LightModeTwoTone color="primary" />
        ) : (
          <DarkModeTwoTone color="primary" />
        )}
      </ListItemIcon>
      <ListItemText primary={darkMode ? "Tema claro" : "Tema oscuro"} />
    </ListItemButton>
  );
}

interface MySidebarProps {
  darkMode: boolean;
  toggleTheme: () => void;
}

const capitalizeFirstLetterXD = (str: string | undefined) => {
  if (str === undefined) {
    return "";
  }

  const arr = str.split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }

  return arr.join(" ");
};

const MySidebar: React.FC<MySidebarProps> = ({ darkMode, toggleTheme }) => {

  const [open, setOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const userName = capitalizeFirstLetterXD(state.user?.name);
  const firstName = userName.split(" ")[0];
  const userType = capitalizeFirstLetterXD(state.user?.role);
  const userClub = capitalizeFirstLetterXD(state.user?.clubName);
  const [filterMenu, setFilterMenu] = useState<any[]>([]);

  useEffect(() => {
    filterMenuByUserAccess();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterMenuByUserAccess = async () => {
    if (state.permissions === undefined) {
      await dispatch({
        type: "set-is-loggedin",
        isLoggedin: false,
      });

      await dispatch({
        type: "set-user",
        user: undefined,
      });

      await dispatch({
        type: "set-token",
        token: undefined,
      });
    }
    var arr = menu.filter((item) => state.permissions.includes(item.id));
    setFilterMenu(arr);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigateToHome = () => {
    navigateWithScroll(navigate, "/");
  };

  const goToItem = (path: string) => {
    navigateWithScroll(navigate, path);
  };

  const handleClickLogOut = () => {
    setIsDialogOpen(true);
  };

  const cancelIOption = () => {
    setIsDialogOpen(false);
  };

  const confirmLogOut = async () => {
    setIsDialogOpen(false);
    await dispatch({
      type: "set-is-loggedin",
      isLoggedin: false,
    });

    await dispatch({
      type: "set-user",
      user: undefined,
    });

    await dispatch({
      type: "set-token",
      token: undefined,
    });

    await dispatch({
      type: "set-permissions",
      token: [],
    });

    navigateWithScroll(navigate, "/");
  };

  const getCurrentViewName = () => {
    const path = location.pathname;

    const currentRoute = RouterPath.find((route) => route.path === path);
    return currentRoute ? currentRoute.label : "";
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <div>
          <AppBar color="primary" position="relative">
            <Toolbar variant="regular">
              <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={navigateToHome}
              >
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "12px",
                  }}
                  alt="company logo"
                  src="/logo256.png"
                />
              </IconButton>
              <Box style={{ flexGrow: "1", textAlign: "center" }}>
                <MyTitle
                  title={getCurrentViewName()}
                  variant="h5"
                  color="white"
                />
              </Box>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuTwoTone className="logo-style" sx={{ color: "white" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            sx={{
              width: 260,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: 260,
                borderRadius: "12px 0 0 12px ",
                border: "none",
              },
            }}
          >
            <List>
              <ListItemButton onClick={navigateToHome}>
                <ListItemAvatar>
                  <AccountCircleTwoTone color="primary" />
                </ListItemAvatar>
                <ListItemText>
                  <MyTitle
                    title={`${firstName} - ${userType}`}
                    variant="body2"
                    color="dark"
                    align="left"
                  />

                  <MyTitle
                    title={userClub}
                    variant="body2"
                    color="dark"
                    align="left"
                  />
                </ListItemText>
              </ListItemButton>
              <Divider />
              <ListItemButton onClick={navigateToHome}>
                <ListItemIcon>
                  <HomeTwoTone color="primary" />
                </ListItemIcon>
                <ListItemText children={"Inicio"} />
              </ListItemButton>
              {filterMenu &&
                filterMenu.map((item: any, index: any) => (
                  <ListItemButton
                    key={index}
                    onClick={() => goToItem(item.path)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                ))}
              <Divider />
              <ThemeToggle darkMode={darkMode} toggleTheme={toggleTheme} />
              <ListItemButton onClick={handleClickLogOut}>
                <ListItemIcon>
                  <LogoutTwoTone color="primary" />
                </ListItemIcon>
                <ListItemText>Cerrar sesión</ListItemText>
              </ListItemButton>
            </List>
          </Drawer>
        </div>
      </ClickAwayListener>

      <MyDialog
        open={isDialogOpen}
        title="Cerrar sesión"
        message="¿Estás seguro de que deseas cerrar la sesión?"
        cancelTextButton="Cancelar"
        confirmTextButton="Aceptar"
        cancelHandle={cancelIOption}
        confirmHandle={confirmLogOut}
      />
    </>
  );
};

export default MySidebar;
