import { Grid, Paper, Stack, Typography, Box, Tabs, Tab, List, ListItem, Chip, ListItemButton, ListSubheader } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const RoutineDetail: React.FC = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container fixed>
            <Grid marginTop={3}>
                <Typography component="h1" variant="h4" align="center" color="primary" >Rutina Detalle</Typography>
            </Grid>

            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                <Stack direction="row" justifyContent="space-evenly" alignItems="center" >

                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="S1" {...a11yProps(0)} />
                        <Tab label="S2" {...a11yProps(1)} />
                        <Tab label="S3" {...a11yProps(2)} />
                        <Tab label="S4" {...a11yProps(2)} />
                    </Tabs>

                </Stack>

                <TabPanel value={value} index={0}>
                    <List >
                        <ListSubheader>Detalles del periodo</ListSubheader>
                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Volumen</h3>
                                        <p>3 x 20</p>
                                    </Typography>
                                    <Chip label="Fuerza (F)" variant="filled" color="primary" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Ejercicios</h3>
                                        <p>3 x 20</p>
                                    </Typography>
                                    <Chip label="Fuerza (F)" variant="filled" color="primary" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Intensidad</h3>
                                        <p>6</p>
                                    </Typography>
                                    <Chip label="Funcional (FU)" variant="filled" color="default" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Volumen</h3>
                                        <p>2</p>
                                    </Typography>
                                    <Chip label="Funcional (FU)" variant="filled" color="default" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Metodo</h3>
                                        <p>Aislado</p>
                                    </Typography>
                                    <Chip label="Funcional (FU)" variant="filled" color="default" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Segmentación</h3>
                                        <p>3 x 20</p>
                                    </Typography>
                                    <Chip label="Funcional (FU)" variant="filled" color="default" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Intensidad</h3>
                                        <p>60%</p>
                                    </Typography>
                                    <Chip label="Cardio (CA)" variant="filled" color="info" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Tiempo</h3>
                                        <p>15 minutos</p>
                                    </Typography>
                                    <Chip label="Cardio (CA)" variant="filled" color="info" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Método</h3>
                                        <p>Continuo</p>
                                    </Typography>
                                    <Chip label="Cardio (CA)" variant="filled" color="info" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                        <ListItem divider>
                            <ListItemButton>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" component="div" >
                                        <h3>Segmentación</h3>
                                        <p>2 x 20</p>
                                    </Typography>
                                    <Chip label="Cardio (CA)" variant="filled" color="info" ></Chip>
                                </Grid>
                            </ListItemButton>
                        </ListItem>

                    </List>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={value} index={3}>
                    Item four
                </TabPanel>

            </Paper>

        </Container>
    );
};

export default RoutineDetail;