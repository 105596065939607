export const resulstDataNutrition = new Map();
const arm = new Map();
arm.set("name", "Brazo");
arm.set("arm_AG", {
  part: "AG",
  unity: "%"
});
arm.set("arm_AM", {
  part: "AM",
  unity: "%"
});
arm.set("arm_AT", {
  part: "AT",
  unity: "%"
});
arm.set("arm_CCOR", {
  part: "CCOR",
  unity: "%"
});

resulstDataNutrition.set("arm", arm);

const bodyComposition = new Map();
bodyComposition.set("name", "Composición Corporal");
bodyComposition.set("muscle", {
  part: "Músculo",
  unity: "Kg"
});
bodyComposition.set("fat", {
  part: "Grasa",
  unity: "Kg"
});
bodyComposition.set("bone", {
  part: "Hueso",
  unity: "Kg"
});
bodyComposition.set("residual", {
  part: "Residual",
  unity: "Kg"
});
bodyComposition.set("residualPercent", {
  part: "Porcentaje residual",
  unity: "%"
});
bodyComposition.set("musclePercent", {
  part: "Porcentaje de Músculo",
  unity: "%"
});
bodyComposition.set("fatPercent", {
  part: "Porcentaje de Grasa",
  unity: "%"
});
bodyComposition.set("bonePercent", {
  part: "Porcentaje de Hueso",
  unity: "%"
});
bodyComposition.set("bodyComposition", {
  part: "Suma",
  unity: "Kg"
});
bodyComposition.set("bodyCompositionDif", {
  part: "Diferencia",
  unity: "Kg"
});

resulstDataNutrition.set("bodyComposition", bodyComposition);

const calf = new Map();
calf.set("name", "Pantorrilla");
calf.set("calf_CCOR", {
  part: "CCOR",
  unity: "%"
});
calf.set("calf_AG", {
  part: "AG",
  unity: "%"
});
calf.set("calf_AT", {
  part: "AT",
  unity: "%"
});
calf.set("calf_AM", {
  part: "AM",
  unity: "%"
});

resulstDataNutrition.set("calf", calf);

const circunferences = new Map();
circunferences.set("name", "Circunferencias");
circunferences.set("calf", {
  part: "Pantorrilla",
  unity: "cm"
});
circunferences.set("contractedArm", {
  part: "Brazo Contraido",
  unity: "cm"
});
circunferences.set("hip", {
  part: "Cadera",
  unity: "cm"
});
circunferences.set("middleThigh", {
  part: "Muslo medio",
  unity: "cm"
});
circunferences.set("relaxArm", {
  part: "Brazo relajado",
  unity: "cm"
});
circunferences.set("waist", {
  part: "Cintura",
  unity: "cm"
});

resulstDataNutrition.set("circunferences", circunferences);

const diameters = new Map();
diameters.set("name", "Diámetros");
diameters.set("bistyloid", {
  part: "Biestiloideo",
  unity: "cm"
});
diameters.set("femur", {
  part: "Fémur",
  unity: "cm"
});
diameters.set("humerus", {
  part: "Humero",
  unity: "cm"
});

resulstDataNutrition.set("diameters", diameters);

const folds = new Map();
folds.set("name", "Pliegues");
folds.set("abs", {
  part: "Abdominal",
  unity: "mm"
});
folds.set("anteriorThigh", {
  part: "Muslo anterior",
  unity: "mm"
});
folds.set("biceps", {
  part: "Biceps",
  unity: "mm"
});
folds.set("calf_MM", {
  part: "Pantorrilla",
  unity: "mm"
});
folds.set("iliacCrest", {
  part: "Cresta Iliaca",
  unity: "mm"
});
folds.set("subscapularis", {
  part: "Subescapular",
  unity: "mm"
});
folds.set("supraspinal", {
  part: "Supraespinal",
  unity: "mm"
});
folds.set("triceps", {
  part: "Triceps",
  unity: "mm"
});

resulstDataNutrition.set("folds", folds);

const indices = new Map();
indices.set("name", "Indices");
indices.set("icc", {
  part: "ICC",
  unity: "NA"
});
indices.set("ice", {
  part: "ICE",
  unity: "NA"
});
indices.set("imc", {
  part: "IMC",
  unity: "NA"
});

resulstDataNutrition.set("indices", indices);

const menAthlete = new Map();
menAthlete.set("name", "Porcentaje de Grasa Ideal");
menAthlete.set("mA_Evans", {
  part: "EVANS",
  unity: "%"
});
menAthlete.set("mA_Stewart", {
  part: "STEWART",
  unity: "%"
});
menAthlete.set("mA_Withers", {
  part: "WITHERS",
  unity: "%"
});

resulstDataNutrition.set("menAthlete", menAthlete);

const menNormal = new Map();
menNormal.set("name", "Hombre normal");
menNormal.set("nM_Durnin", {
  part: "DURNIN",
  unity: "%"
});
menNormal.set("nM_Katch", {
  part: "Katch",
  unity: "%"
});
menNormal.set("nM_Lean", {
  part: "LEAN",
  unity: "%"
});
menNormal.set("nM_Peterson", {
  part: "PETERSON",
  unity: "%"
});
menNormal.set("nM_Wilmore", {
  part: "WILMORE",
  unity: "%"
});

//resulstDataNutrition.set("menNormal", menNormal);

const somatype = new Map();
somatype.set("name", "Somatotipo");
somatype.set("ectomorph", {
  part: "Ectomorfo",
  unity: "NA"
});
somatype.set("endomorph", {
  part: "Endomorfo",
  unity: "NA"
});
somatype.set("mesomorph", {
  part: "Mesomorfo",
  unity: "NA"
});
somatype.set("somatotypE_X", {
  part: "X",
  unity: "NA"
});
somatype.set("somatotypE_Y", {
  part: "Y",
  unity: "NA"
});

resulstDataNutrition.set("somatype", somatype);

const thigh = new Map();
thigh.set("name", "Muslo");
thigh.set("thigh_AG", {
  part: "AG",
  unity: "%"
});
thigh.set("thigh_AM", {
  part: "AM",
  unity: "%"
});
thigh.set("thigh_AT", {
  part: "AT",
  unity: "%"
});
thigh.set("thigh_CCOR", {
  part: "CCOR",
  unity: "%"
});

resulstDataNutrition.set("thigh", thigh);

const womenAthlete = new Map();
womenAthlete.set("name", "Porcentaje de Grasa Ideal");
womenAthlete.set("wA_Evans", {
  part: "EVANS",
  unity: "%"
});
womenAthlete.set("wA_Thorland", {
  part: "THORLAND",
  unity: "%"
});
womenAthlete.set("wA_Withers", {
  part: "WITHERS",
  unity: "%"
});

resulstDataNutrition.set("womenAthlete", womenAthlete);

const womenNormal = new Map();
womenNormal.set("name", "Mujer normal");
womenNormal.set("nW_Ball", {
  part: "BALL",
  unity: "%"
});
womenNormal.set("nW_Durnin", {
  part: "DURNIN",
  unity: "%"
});
womenNormal.set("nW_Jackson", {
  part: "JACKSON",
  unity: "%"
});
womenNormal.set("nW_Lean", {
  part: "LEAN",
  unity: "%"
});
womenNormal.set("nW_Peterson", {
  part: "PETERSON",
  unity: "%"
});
womenNormal.set("nW_Wilmore", {
  part: "WILMORE",
  unity: "%"
});

//resulstDataNutrition.set("womenNormal", womenNormal);
