import {
  PeopleTwoTone,
  RestaurantTwoTone,
  FitnessCenter,
} from "@mui/icons-material";

export const menu = [
  {
    id: 1,
    title: "Entrenamiento",
    path: "/training",
    icon: <FitnessCenter color="primary" />
  },
  {
    id: 2,
    title: "Nutrición",
    path: "/nutrition",
    icon: <RestaurantTwoTone color="secondary" />
  },
  {
    id: 3,
    title: "Socios",
    path: "/users",
    icon: <PeopleTwoTone color="secondary" />
  },
];
