import { Card, useTheme } from "@mui/material"
import { customColorsLight, customColorsDark } from "../../../theme/theme";

interface MyCardProps {
    color?: keyof typeof customColorsLight | keyof typeof customColorsDark;
    children?: React.ReactNode;
}

const MyCard: React.FC<MyCardProps> = ({
    color,
    children,
}) => {

    const theme = useTheme();
    let backgroundColor = "";

    if (color) {
        if (theme.palette.mode === "light" && customColorsLight[color]) {
            backgroundColor = customColorsLight[color].main;
        } else if (theme.palette.mode === "dark" && customColorsDark[color]) {
            backgroundColor = customColorsDark[color].main;
        }
    }

    return (

        <Card style={{ boxShadow: 'none', borderRadius: '12px', backgroundColor }} sx={{ my: { xs: 2, md: 4 }, p: { xs: 2, md: 3 } }} >
            {children}
        </Card>
    )
};

export default MyCard;