import React from "react";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

interface MyAlertProps {
    open?: boolean
    autoHideDuration?: number,
    title?: string,
    message?: string,
    severity?: 'error' | 'info' | 'success' | 'warning',
    variant?: 'filled' | 'outlined' | 'standard',
    closeText?: string,
    handleClose?: () => void,
};

const MyAlert: React.FC<MyAlertProps> = ({
    open,
    autoHideDuration = 3000,
    handleClose,
    title = 'Placeholder title',
    message = 'Placeholder message',
    severity = 'success',
    variant = 'standard',
    closeText = 'Cerrar'
}) => {

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose} >
            <Alert sx={{ width: '100%' }}
                onClose={handleClose}
                severity={severity}
                variant={variant}
                closeText={closeText}
            >
                <AlertTitle>{title}</AlertTitle>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default MyAlert;