import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

interface MyAutoCompleteInputProps {
  items: any[];
  label: string;
  widthSize?: string;
  value: any;
  onChange: (value: any) => void;
}

const MyAutoCompleteInput: React.FC<MyAutoCompleteInputProps> = ({
  items,
  label,
  widthSize,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="my-autocomplete"
      options={items}
      sx={{ width: widthSize || "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

export default MyAutoCompleteInput;
