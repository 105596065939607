import React, { useContext, useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { AppContext, AppContextProvider } from "./data/state";
import { RouterPath } from "./Router/Router";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { lightTheme, darkTheme } from "./theme/theme";
import { MyLoading, MySidebar } from './components/Generic/Index';
import { navigateWithScroll } from "./functions/navigateUtils";
import { useNavigate } from "react-router-dom";
import ProtectedRoutes from "./components/Generic/ProtectedRoutes/ProtectedRoutes";
import server from './api/server';

import "./App.css";

const SignIn = React.lazy(() => import("./views/SignIn/SignIn"));

interface MoveAppStaffProps {
  darkMode: boolean;
  toggleTheme: () => void;
};

const MoveAppStaff: React.FC<MoveAppStaffProps> = ({ darkMode, toggleTheme }) => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user !== undefined) {
      checkToken();
    }
  }, []);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const checkToken = async () => {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${state.token}`,
    };

    try {
      await server.post(
        `/staff/auth/validate-token`,
        "",
        { headers: headers }
      );

      if(state.permissions === undefined) {
        clearCacheData();
        await dispatch({
          type: "set-is-loggedin",
          isLoggedin: false,
        });

        await dispatch({
          type: "set-user",
          user: undefined,
        });

        await dispatch({
          type: "set-token",
          token: undefined,
        });

        await dispatch({
          type: "set-permissions",
          permissions: [],
        });
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        clearCacheData();
        await dispatch({
          type: "set-is-loggedin",
          isLoggedin: false,
        });

        await dispatch({
          type: "set-user",
          user: undefined,
        });

        await dispatch({
          type: "set-token",
          token: undefined,
        });

        await dispatch({
          type: "set-permissions",
          permissions: [],
        });

        navigateWithScroll(navigate, '/');
        window.location.reload();
      }
    }
  };

  return (
    <div className="App">
      {state.isLoggedin ? (
        <>
          <MySidebar darkMode={darkMode} toggleTheme={toggleTheme} />
          <Routes>
            {RouterPath.map((item, index) => (
              <Route
               key={index}
               path={item.path}
               element={
                 <ProtectedRoutes
                   permissions={state.permissions}
                   pathGroup={item.id!}
                   component={item.element}
                 />
               }
             />
            ))}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<MyLoading />}>
                <SignIn />
              </React.Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </div>
  );
};

const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const toggleTheme = (): void => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  return (
    <AppContextProvider>
      <BrowserRouter>
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <CssBaseline />
          <MoveAppStaff darkMode={darkMode} toggleTheme={toggleTheme} />
        </ThemeProvider>
      </BrowserRouter>
    </AppContextProvider>
  );
};

export default App;
