import React from "react";
import { Button } from "@mui/material";

interface MyButtonProps {
    title?: string,
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    variant?: 'contained' | 'outlined' | 'text',
    fullWidth?: boolean,
    size?: 'small' | 'medium' | 'large',
    disabled?: boolean,
    startIcon?: any,
    endIcon?: any,
    handleClick?: () => void,
};

const MyButton: React.FC<MyButtonProps> = ({
    title = 'Placeholder',
    color = 'primary',
    variant = 'outlined',
    fullWidth,
    size = 'medium',
    disabled = false,
    startIcon,
    endIcon,
    handleClick
}) => {

    return (
        <Button style={{ borderRadius: '8px' }}
            color={color}
            variant={variant}
            fullWidth={fullWidth}
            size={size}
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            onClick={handleClick}
        >
            {title}
        </Button>
    )
};

export default MyButton;