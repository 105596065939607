import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
      paper: '#f4f5f8',
    },
    primary: {
      light: '#9fcb77',
      main: '#88BE55',
      dark: '#5f853b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9bab8a',
      main: '#83976d',
      dark: '#5b694c',
      contrastText: '#fff',
    },
    inherit: {
      light: '#eeeeee',
      main: '#bdbdbd',
      dark: '#757575',
      contrastText: '#000',
    },
    success: {
      light: '#b4e79f',
      main: '#a2e188',
      dark: '#719d5f',
      contrastText: '#fff',
    },
    error: {
      light: '#ce4546',
      main: '#c21718',
      dark: '#871010',
      contrastText: '#fff',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#fff',
    }
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#1c1c1c',
    },
    primary: {
      light: '#b2d592',
      main: '#9fcb77',
      dark: '#6f8e53',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9bab8a',
      main: '#83976d',
      dark: '#5b694c',
      contrastText: '#fff',
    },
    inherit: {
      light: '#eeeeee',
      main: '#bdbdbd',
      dark: '#757575',
      contrastText: '#000',
    },
    success: {
      light: '#b4e79f',
      main: '#a2e188',
      dark: '#719d5f',
      contrastText: '#fff',
    },
    error: {
      light: '#ce4546',
      main: '#c21718',
      dark: '#871010',
      contrastText: '#fff',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#fff',
    }
  },
});

const customColorsLight = {
  light: {
    light: '#f6f7f9',
    main: '#f4f5f8',
    dark: '#aaabad',
    contrastText: 'fff',
  },
  medium: {
    light: '#a7a9af',
    main: '#92949c',
    dark: '#66676d',
    contrastText: 'fff',
  },
  dark: {
    light: '#4e4f53',
    main: '#222428',
    dark: '#17191c',
    contrastText: 'fff',
  }
};

const customColorsDark = {
  light: {
    light: '#4e4f53',
    main: '#222428',
    dark: '#17191c',
    contrastText: 'fff',
  },
  medium: {
    light: '#acaeb4',
    main: '#989aa2',
    dark: '#6a6b71',
    contrastText: 'fff',
  },
  dark: {
    light: '#f6f7f9',
    main: '#f4f5f8',
    dark: '#aaabad',
    contrastText: 'fff',
  }
};

export { lightTheme, darkTheme, customColorsLight, customColorsDark };