import { Grid } from "@mui/material"

interface MyItemProps {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    children?: React.ReactNode;
}

const MyItem: React.FC<MyItemProps> = ({

    xs = 12,
    md = 12,
    direction,
    justifyContent,
    alignItems,
    children,

}) => {

    return (
        <Grid item
            xs={xs}
            md={md}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
        >
            {children}
        </Grid>
    )
};

export default MyItem;