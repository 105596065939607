import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MyButton } from '../Index';

interface MyDialogProps {
  open?: boolean,
  title?: string,
  message?: string,
  cancelTextButton: string,
  confirmTextButton: string,
  cancelHandle?: () => void,
  confirmHandle?: () => void,
  extraButton?: boolean,
  extraHandle?: () => void,
  extraTextButton?: string,
};

const MyDialog: React.FC<MyDialogProps> = ({
  open = false,
  cancelHandle,
  confirmHandle,
  title = '',
  message = '',
  cancelTextButton = 'Cancelar',
  confirmTextButton = 'Aceptar',
  extraButton = false,
  extraHandle,
  extraTextButton = '',
}) => {

    return (
      <Dialog
        PaperProps={{ style: { borderRadius: '12px' } }}
        open={open}
        onClose={cancelHandle}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MyButton title={cancelTextButton} handleClick={cancelHandle} variant='text' />
          {
            extraButton &&
              <MyButton title={extraTextButton} handleClick={extraHandle} variant='text' />
          }
          <MyButton title={confirmTextButton} handleClick={confirmHandle} variant='text' />
        </DialogActions>
      </Dialog>
    );
}

export default MyDialog;
