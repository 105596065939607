import { useEffect, useState, useContext } from "react";
import { navigateWithScroll } from "../../functions/navigateUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInfoService } from "../../services/User";
import { NewConsultService } from "../../services/Nutrition/NewConsult";
import { GetConsultObjectivesService } from "../../services/Nutrition/ConsultObjective";
import { NewConsult } from "../../Models/Nutrition/NewConsult";
import { AppContext } from "../../data/state";
import server from "../../api/server";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import moment from "moment";
import "moment/locale/es";
import {
  Container,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import {
  MyTitle,
  MyLoading,
  MyButton,
  MyTextField,
  MyDialog,
  MyAlert,
  MySelect,
  MyTooltip,
} from "../../components/Generic/Index";

const AnthropometricEvaluation: React.FC = () => {
  const location: any = useLocation();
  const { state } = useContext(AppContext);
  const token = state.token;
  const navigate = useNavigate();
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formatDate = (date: any) =>
    moment(date).locale("es").format("DD [de] MMMM [de] YYYY");
  const formatMinDate = (date: any) => moment(date).format("YYYY-MM-DD");
  const [activeStep, setActiveStep] = useState(0);
  const totalSteps = 2;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState(false);
  const youtubeLink = "https://www.youtube.com/watch?v=k-gAzrysf-4";

  const [ticketNumber, setTicketNumber] = useState("");

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [userNutritionID, setUserNutritionID] = useState(0);
  const [userCreatedConsultID, setUserCreatedConsultID] = useState(0);
  const [consultTypeID, setConsultTypeID] = useState(0);
  const [userNumberSession, setUserNumberSession] = useState(0);

  const [relaxedArm, setRelaxedArm] = useState("");
  const [contractedArm, setContractedArm] = useState("");
  const [waist, setWaist] = useState("");
  const [hip, setHip] = useState("");
  const [midThigh, setMidThigh] = useState("");
  const [calf, setCalf] = useState("");

  const [humerus, setHumerus] = useState("");
  const [femur, setFemur] = useState("");
  const [biestiloid, setBiestiloid] = useState("");

  const [triceps, setTriceps] = useState("");
  const [subscapular, setSubscapular] = useState("");
  const [biceps, setBiceps] = useState("");
  const [iliacCrest, setIliacCrest] = useState("");
  const [supraspinale, setSupraspinale] = useState("");
  const [abdominal, setAbdominal] = useState("");
  const [anteriorThigh, setAnteriorThigh] = useState("");
  const [foldsCalf, setFoldsCalf] = useState("");

  const [weight, setWeight] = useState("");
  const [bodyFatPercentage, setBodyFatPercentage] = useState("");
  const [leanBodyMassKg, setLeanBodyMassKg] = useState("");
  const [leanBodyMassPercentage, setLeanBodyMassPercentage] = useState("");
  const [visceralFat, setVisceralFat] = useState("");
  const [size, setSize] = useState("");
  const [IMC, setIMC] = useState("");
  const [hydration, setHydration] = useState("");
  const [bodyFatKg, setBodyFatKg] = useState("");
  const [metabolicAge, setMetabolicAge] = useState("");
  const [spine, setSpine] = useState("");
  const [goal, setGoal] = useState("");
  const [goalDescription, setGoalDescription] = useState("");
  const [goalList, setGoalList] = useState([]);
  const [nextAppointment, setNextAppointment] = useState("");

  const [dialogTitleText, setDialogTitleText] = useState("");
  const [dialogMessageText, setDialogMessageText] = useState("");

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId);
      setUserNumberSession(location.state.sessionNum + 1);
      setUserNutritionID(location.state.userNutritionID);
      setConsultTypeID(
        location.state.consultTypeID ? location.state.consultTypeID : 1
      );
      requestUserInfo(location.state.userId);
    }
    requestConsultObjective();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestUserInfo = async (id: any) => {
    setIsActive(true);
    try {
      const userInfo = await UserInfoService.getUser(token, id);
      setUserName(userInfo.name);

      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const requestConsultObjective = async () => {
    setIsActive(true);
    try {
      const objectivesList = await GetConsultObjectivesService.getObjectives(
        token
      );

      const newArray = objectivesList.map((item: any) => {
        return { id: item.id, label: item.objective };
      });

      setGoalList(newArray);
      setIsActive(false);
    } catch (error: any) {
      setIsActive(false);
    }
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handlePrevious = () => {
    const previousStep = activeStep - 1;
    if (previousStep >= 0) {
      handleStepChange(previousStep);
    }
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    if (nextStep < totalSteps) {
      handleStepChange(nextStep);
    }
  };

  const cancelAlertHandle = () => {
    setIsAlertOpen(false);
  };

  const saveData = async () => {
    setIsActive(true);

    const newConsult: NewConsult = {
      userNutritionID,
      consultDate: new Date().toISOString(),
      objective: goal,
      consultTypeID,
      relaxArm: parseFloat(relaxedArm),
      contractedArm: parseFloat(contractedArm),
      waist: parseFloat(waist),
      hip: parseFloat(hip),
      middleThigh: parseFloat(midThigh),
      calf: parseFloat(calf),
      calf_MM: parseFloat(foldsCalf),
      humerus: parseFloat(humerus),
      femur: parseFloat(femur),
      bistyloid: parseFloat(biestiloid),
      triceps: parseFloat(triceps),
      subscapularis: parseFloat(subscapular),
      biceps: parseFloat(biceps),
      iliacCrest: parseFloat(iliacCrest),
      supraspinal: parseFloat(supraspinale),
      abs: parseFloat(abdominal),
      anteriorThigh: parseFloat(anteriorThigh),
      weight: parseFloat(weight),
      fatAverage: parseFloat(bodyFatPercentage),
      mmKg: parseFloat(leanBodyMassKg),
      mmAverage: parseFloat(leanBodyMassPercentage),
      visceralFat: parseFloat(visceralFat),
      height: parseFloat(size),
      h2O: parseFloat(hydration),
      mgKg: parseFloat(bodyFatKg),
      spine: parseFloat(spine),
      polarContractID: ticketNumber,
    };

    try {
      const createdConsult = await NewConsultService.createConsult(
        token,
        newConsult
      );
      setUserCreatedConsultID(createdConsult.id);
      setIsActive(false);
      setDialogTitleText("Evaluación guardada correctamente");
      setDialogMessageText("¿Desea continuar a la vista de carga de fotos?");
      setIsAlertOpen(true);
      setIsDialogOpen(true);
      setError(false);
    } catch (error: any) {
      setError(true);
      setDialogTitleText("Error al guardar la evaluación");
      setDialogMessageText("Favor de revisar los datos ingresados.");
      console.log(error);
      setIsDialogOpen(true);
      setIsActive(false);
    }
  };

  const cancelDialoghandle = () => {
    setIsAlertOpen(false);
    navigateWithScroll(navigate, "/nutrition");
  };

  const confirmDialoghandle = () => {
    setIsAlertOpen(false);
    if (error) {
      setIsDialogOpen(false);
    } else {
      navigateWithScroll(navigate, "/nutrition/evidence", {
        state: {
          consultId: userCreatedConsultID,
          userId: location.state.userId,
        },
      });
    }
  };

  const skipEvidenceHandle = async () => {
    setIsActive(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const responseServer = await server.put(
        `staff/nutrition-consult/complete-photos/${userCreatedConsultID}`,
        JSON.stringify(""),
        { headers }
      );

      setIsDialogOpen(false);
      navigateWithScroll(navigate, "/nutrition");
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const onChangeDatePicker = (value: any) => {
    setNextAppointment(value);
  };

  const calculateIMC = (currentWeight: any, currentSize: any) => {
    if (
      currentWeight === "" ||
      currentSize === "" ||
      currentWeight === "0" ||
      currentSize === "0"
    ) {
      return "0";
    }

    const weight = parseFloat(currentWeight);
    const size = parseFloat(currentSize) / 100;

    const imc = weight / (size * size);

    return imc.toFixed(1);
  };

  const onChangeWeigth = (value: any) => {
    setIMC(calculateIMC(value, size));
    setWeight(value);
  };

  const onChangeSize = (value: any) => {
    setIMC(calculateIMC(weight, value));
    setSize(value);
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <Grid marginTop={3}>
          <Stepper activeStep={activeStep} nonLinear alternativeLabel>
            <Step onClick={() => handleStepChange(0)}>
              <StepLabel>Circunferencias y Diámetros</StepLabel>
            </Step>
            <Step onClick={() => handleStepChange(1)}>
              <StepLabel>Pliegues y Datos</StepLabel>
            </Step>
          </Stepper>
        </Grid>
        {activeStep === 0 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={6}>
                  <MyTitle
                    title={userName}
                    variant="h5"
                    align="left"
                    color="primary"
                    noWrap
                  />
                  <MyTitle
                    title={`Número de socio: ${userId}`}
                    variant="subtitle2"
                    align="left"
                    color="dark"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={6}>
                  <MyTitle
                    title={`Sesion # ${userNumberSession}`}
                    variant="h5"
                    align="right"
                    color="primary"
                    gutterBottom
                    noWrap
                  />

                  <MyTitle
                    title={`${formatDate(new Date())}`}
                    variant="subtitle2"
                    align="right"
                    color="dark"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={10} md={11}>
                  <MyTitle
                    title="Circunferencias"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={2} md={1}>
                  <MyTooltip description="Video Descripción de como hacer las mediciones">
                    <a
                      href={youtubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HelpTwoToneIcon />
                    </a>
                  </MyTooltip>
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Brazo relajado"
                    value={relaxedArm}
                    onChange={(e) => setRelaxedArm(e.target.value)}
                    type="number"
                    id="relaxedArm"
                    name="relaxedArm"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Brazo Contraido"
                    value={contractedArm}
                    onChange={(e) => setContractedArm(e.target.value)}
                    type="number"
                    id="contractedArm"
                    name="contractedArm"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Cintura"
                    value={waist}
                    onChange={(e) => setWaist(e.target.value)}
                    type="number"
                    id="waist"
                    name="waist"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Cadera"
                    value={hip}
                    onChange={(e) => setHip(e.target.value)}
                    type="number"
                    id="hip"
                    name="hip"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Muslo medio"
                    value={midThigh}
                    onChange={(e) => setMidThigh(e.target.value)}
                    type="number"
                    id="midThigh"
                    name="midThigh"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Pantorrilla"
                    value={calf}
                    onChange={(e) => setCalf(e.target.value)}
                    type="number"
                    id="calf"
                    name="calf"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MyTitle
                    title="Diámetros"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Humero"
                    value={humerus}
                    onChange={(e) => setHumerus(e.target.value)}
                    type="number"
                    id="humerus"
                    name="humerus"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Biestiloideo"
                    value={biestiloid}
                    onChange={(e) => setBiestiloid(e.target.value)}
                    type="number"
                    id="biestiloid"
                    name="biestiloid"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Fémur"
                    value={femur}
                    onChange={(e) => setFemur(e.target.value)}
                    type="number"
                    id="femur"
                    name="femur"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Grid
                container
                spacing={2}
                marginBottom={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={10} md={11}>
                  <MyTitle
                    title="Pliegues"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={2} md={1}>
                  <MyTooltip description="Video Descripción de como hacer las mediciones">
                    <a
                      href={youtubeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HelpTwoToneIcon />
                    </a>
                  </MyTooltip>
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Triceps"
                    value={triceps}
                    onChange={(e) => setTriceps(e.target.value)}
                    type="number"
                    id="triceps"
                    name="triceps"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Subescapular"
                    value={subscapular}
                    onChange={(e) => setSubscapular(e.target.value)}
                    type="number"
                    id="subscapular"
                    name="subscapular"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Biceps"
                    value={biceps}
                    onChange={(e) => setBiceps(e.target.value)}
                    type="number"
                    id="biceps"
                    name="biceps"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Cresta Iliaca"
                    value={iliacCrest}
                    onChange={(e) => setIliacCrest(e.target.value)}
                    type="number"
                    id="iliacCrest"
                    name="iliacCrest"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Supraespinal"
                    value={supraspinale}
                    onChange={(e) => setSupraspinale(e.target.value)}
                    type="number"
                    id="supraspinale"
                    name="supraspinale"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Abdominal"
                    value={abdominal}
                    onChange={(e) => setAbdominal(e.target.value)}
                    type="number"
                    id="abdominal"
                    name="abdominal"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Muslo anterior"
                    value={anteriorThigh}
                    onChange={(e) => setAnteriorThigh(e.target.value)}
                    type="number"
                    id="anteriorThigh"
                    name="anteriorThigh"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Pantorrilla"
                    value={foldsCalf}
                    onChange={(e) => setFoldsCalf(e.target.value)}
                    type="number"
                    id="foldsCalf"
                    name="foldsCalf"
                    hasInputAdornment
                    inputAdornmentText="mm"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <MyTitle
                    title="Datos"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Peso"
                    value={weight}
                    onChange={(e) => onChangeWeigth(e.target.value)}
                    type="number"
                    id="weight"
                    name="weight"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Talla"
                    value={size}
                    onChange={(e) => onChangeSize(e.target.value)}
                    type="number"
                    hasInputAdornment
                    inputAdornmentText="cm"
                    id="size"
                    name="size"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="IMC"
                    value={IMC}
                    type="text"
                    id="IMC"
                    name="IMC"
                    hasInputAdornment
                    inputAdornmentText="%"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Porcentaje de Grasa"
                    value={bodyFatPercentage}
                    onChange={(e) => setBodyFatPercentage(e.target.value)}
                    type="number"
                    id="bodyFatPercentage"
                    name="bodyFatPercentage"
                    hasInputAdornment
                    inputAdornmentText="%"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="MGKg"
                    value={bodyFatKg}
                    onChange={(e) => setBodyFatKg(e.target.value)}
                    type="number"
                    id="bodyFatKg"
                    name="bodyFatKg"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="%MM"
                    value={leanBodyMassPercentage}
                    onChange={(e) => setLeanBodyMassPercentage(e.target.value)}
                    type="number"
                    id="leanBodyMassPercentage"
                    name="leanBodyMassPercentage"
                    hasInputAdornment
                    inputAdornmentText="%"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="MMkg"
                    value={leanBodyMassKg}
                    onChange={(e) => setLeanBodyMassKg(e.target.value)}
                    type="number"
                    id="leanBodyMassKg"
                    name="leanBodyMassKg"
                    hasInputAdornment
                    inputAdornmentText="kg"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Grasa visceral"
                    value={visceralFat}
                    onChange={(e) => setVisceralFat(e.target.value)}
                    type="number"
                    id="visceralFat"
                    name="visceralFat"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Edad Metabolica"
                    value={metabolicAge}
                    onChange={(e) => setMetabolicAge(e.target.value)}
                    type="number"
                    id="metabolicAge"
                    name="metabolicAge"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="H2O"
                    value={hydration}
                    onChange={(e) => setHydration(e.target.value)}
                    type="number"
                    id="hydration"
                    name="hydration"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MySelect
                    title="Seleccione su objectivo"
                    placeholder="Objetivo de la consulta"
                    items={goalList}
                    value={goal}
                    onChange={(value) => setGoal(value)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MyTextField
                    label="Descripcion del objetivo"
                    value={goalDescription}
                    onChange={(e) => setGoalDescription(e.target.value)}
                    type="text"
                    id="goalDescription"
                    name="goalDescription"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <MyTextField
                    label="Proxima cita"
                    value={nextAppointment}
                    onChange={(e) => onChangeDatePicker(e.target.value)}
                    type="date"
                    id="nextAppointment"
                    name="nextAppointment"
                    initialValue={formatDate(new Date())}
                    minDate={formatMinDate(tomorrow)}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={10} md={2}>
                  <MyTitle
                    title="Contrato"
                    variant="h5"
                    align="left"
                    color="primary"
                    gutterBottom
                    noWrap
                  />
                </Grid>

                <Grid item xs={2} md={10}>
                  <MyTooltip description="Recuerda que el número de contrato está en el recibo, en caso de que el cliente no cuente con uno, se generará un nuevo cobro en Polar.">
                    <HelpTwoToneIcon />
                  </MyTooltip>
                </Grid>

                <Grid item xs={12} md={4}>
                  <MyTextField
                    label="Numero de contrato"
                    value={ticketNumber}
                    onChange={(e) => setTicketNumber(e.target.value)}
                    type="text"
                    id="ticketNumber"
                    name="ticketNumber"
                    hasInputAdornment
                    inputAdornmentText="#"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={8} />
                <Grid item xs={8} />

                <Grid item xs={12} md={4} paddingTop={2}>
                  <MyButton
                    title="Guardar Sesion"
                    color="primary"
                    size="medium"
                    handleClick={saveData}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <MyButton
              title="Anterior"
              color="primary"
              handleClick={handlePrevious}
            />
          </div>
          {activeStep + 1 !== totalSteps && (
            <div>
              <MyButton
                title="Siguiente"
                color="primary"
                handleClick={handleNext}
              />
            </div>
          )}
        </div>
      </Container>

      <MyAlert
        open={isAlertOpen}
        severity="success"
        title="¡Éxito!"
        message="Evaluación guardada correctamente."
        closeText="Cerrar"
        handleClose={cancelAlertHandle}
      />

      <MyDialog
        open={isDialogOpen}
        title={dialogTitleText}
        message={dialogMessageText}
        cancelTextButton="Despues"
        confirmTextButton="Anexar evidencia"
        cancelHandle={cancelDialoghandle}
        confirmHandle={confirmDialoghandle}
        extraButton={true}
        extraHandle={skipEvidenceHandle}
        extraTextButton="Omitir"
      />
    </>
  );
};

export default AnthropometricEvaluation;
