import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import {
  MyCard,
  MyItem,
  MyItemContainer,
  MyTitle,
  MyTable,
  MyLoading,
} from "../../components/Generic/Index";
import { GetUserHistoryService } from "../../services/Nutrition/HistoricalView/GetHistory";
import { AppContext } from "../../data/state";
import moment from "moment";
const formatDate = (date: any) => moment(date).format("DD/MM/YY");

const Historical: React.FC = () => {
  const location: any = useLocation();
  const [userId, setUserId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [historialData, setHistorialData] = useState<any>();
  const [circunferencesData, setCircunferencesData] = useState<any>();
  const [foldsData, setFoldsData] = useState<any>();
  const [diametersData, setDiametersData] = useState<any>();
  const { state } = useContext(AppContext);
  const token = state.token;

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId);
      loadData(location.state.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (id: any) => {
    setIsActive(true);
    try {
      const response = await GetUserHistoryService.getHistory(id, token);
      const dates = response.dates?.map((item: any) => {
        return formatDate(item);
      });
      setHistorialData({
        "Fecha": dates,
        "Talla": response.weight,
        "IMC": response.imc,
      });
      setCircunferencesData({
        "Fecha": dates,
        "Brazo relajado": response.circunferencesRelaxArm,
        "Brazo contraido": response.circunferencesContractedArm,
        "Pantorrilla": response.circunferencesCalf,
        "Cadera": response.circunferencesHip,
        "Cintura": response.circunferencesWaist,
        "Muslo medio": response.circunferencesMiddleThigh,
      });
      setFoldsData({
        "Fecha": dates,
        "ABS": response.foldsABS,
        "Muslo anterior": response.foldsAnteriorThigh,
        "Bicep": response.foldsBiceps,
        "Tricep": response.foldsTriceps,
        "Cresta Ileaica": response.foldsIliacCrest,
        "Pantorrilla": response.foldsCalf_MM,
        "Subescapular": response.foldsSubscapularis,
        "Supraespinal": response.foldsSupraspinal,
      });
      setDiametersData({
        "Fecha": dates,
        "Biestiloideo": response.diametersBistyloid,
        "Femur": response.diametersFemur,
        "Humero": response.diametersHumerus,
      });
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <MyCard>
          <MyItemContainer>
            <MyItem>
              <MyTitle
                title={`Resultados del socio ${userId}`}
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </MyItem>
            <MyItem>
              <MyTable data={historialData} />
            </MyItem>
          </MyItemContainer>
        </MyCard>
        <MyCard>
          <MyItemContainer>
            <MyItem>
              <MyTitle
                title="Circunferencias (Cm)"
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </MyItem>
            <MyItem>
              <MyTable data={circunferencesData} />
            </MyItem>
          </MyItemContainer>
        </MyCard>
        <MyCard>
          <MyItemContainer>
            <MyItem>
              <MyTitle
                title="Pliegues (Mm)"
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </MyItem>
            <MyItem>
              <MyTable data={foldsData} />
            </MyItem>
          </MyItemContainer>
        </MyCard>
        <MyCard>
          <MyItemContainer>
            <MyItem>
              <MyTitle
                title="Diametros (Mm)"
                variant="h5"
                align="left"
                color="primary"
                gutterBottom
                noWrap
              />
            </MyItem>
            <MyItem>
              <MyTable data={diametersData} />
            </MyItem>
          </MyItemContainer>
        </MyCard>
      </Container>
    </>
  );
};

export default Historical;
