import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography, Chip, TableRow, TableCell, TableBody, TableContainer, Table, TableHead } from "@mui/material";
import { Container } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from "../../../theme/colors";

const RoutineResume: React.FC = () => {

    // const [isOpen, setIsOpen] = useState(false);

    return (
        <Container fixed >
            <Grid marginTop={3}>
                <Typography component="h1" variant="h4" align="center" color="primary" >Resumen</Typography>
            </Grid>

            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>

                <Stack direction="row" justifyContent="space-evenly" alignItems="center" >
                    <TextField id="numberClient" value={"123456"} label="Numero de socio" variant="outlined" color="primary" />
                    <TextField id="nameClient" value={"Mario Moreno"} label="Nombre de socio" variant="outlined" color="primary" />

                    <FormControl>
                        <InputLabel id="period">Periodo</InputLabel>
                        <Select onChange={() => { }} labelId="period-select" defaultValue={"1"} autoWidth label="Periodo">
                            <MenuItem value={"1"} >Semana 1</MenuItem>
                            <MenuItem value={"2"} >Semana 2</MenuItem>
                            <MenuItem value={"3"} >Semana 3</MenuItem>
                            <MenuItem value={"4"} >Semana 4</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <Grid marginTop={3}>
                    <Typography component="h1" variant="h4" align="center" ></Typography>
                </Grid>

                <Accordion >
                    <AccordionSummary color="primary" style={{ backgroundColor: Colors.primary, color: Colors.white, }} id="AccordionHeader" aria-controls="AccordionHeader" expandIcon={<ExpandMoreIcon />} >
                        <Typography>Detalles</Typography>
                    </AccordionSummary>

                    <AccordionDetails >
                        <TableContainer>
                            <Table >

                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" >Dia</TableCell>
                                        <TableCell align="center" >Hora de inicio</TableCell>
                                        <TableCell align="center" >Desempeño</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" >
                                            <Typography variant="body1" component="div" >Lunes</Typography>
                                        </TableCell>

                                        <TableCell align="center" >
                                            <Typography variant="body1" component="div" >5:30 PM</Typography>
                                        </TableCell>

                                        <TableCell align="center" >
                                            <Chip label="4/10" variant="filled" color="warning" ></Chip>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Martes</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Miércoles</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >5:15 PM</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Chip label="8/10" variant="filled" color="primary" ></Chip>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Jueves</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Viernes</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >No asistio</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Chip label="0/10" variant="filled" color="error" ></Chip>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Sábado</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >Domingo</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Typography variant="body1" component="div" >-</Typography>
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

            </Paper>

        </Container>
    );
};

export default RoutineResume;