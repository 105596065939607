import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  ListItem,
  Divider,
  List
} from "@mui/material";
import "./styles.css";
import { Colors } from "../../../theme/colors";

const RoutineTwo: React.FC = () => {
  return (
    <>
      <div className="container-app">
        <Grid>
          <Grid marginTop={3}>
            <Typography
              variant="h4"
              component="h4"
              color="primary"
              textAlign={"center"}
            >
              Rutina 2
            </Typography>
          </Grid>
          <Grid>
            <Card>
              <CardContent>
                <Typography variant="body1" component="h6">
                  Crear rutina
                </Typography>

                <Grid>
                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="nameRoutine"
                      label="Nombre de rutina"
                      variant="standard"
                      color="primary"
                      name="nameRoutine"
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="category-label">Categoria</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        onChange={() => {}}
                        defaultValue={"fuerza"}
                      >
                        <MenuItem value={"fuerza"}>Fuerza</MenuItem>
                        <MenuItem value={"funcional"}>Funcional</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="exercise-label">Ejercicio</InputLabel>
                      <Select
                        labelId="exercise-label"
                        id="exercise"
                        onChange={() => {}}
                        defaultValue={"Pecho"}
                      >
                        <MenuItem value={"Pecho"}>Pecho</MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="series-label">
                        Press Pecho Horizontal
                      </InputLabel>
                      <Select
                        labelId="series-label"
                        id="series"
                        onChange={() => {}}
                        defaultValue={"Pecho"}
                      >
                        <MenuItem value={"Pecho"}>
                          Press Pecho Horizontal
                        </MenuItem>
                        <MenuItem value={"B"}>B</MenuItem>
                        <MenuItem value={"C"}>C</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg paddingBottom={2}>
                    <TextField
                      id="series-personalized"
                      label="Agrega una instruccion personalizada"
                      variant="standard"
                      name="series-personalized"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/*  */}
          <Grid marginTop={3}>
            <Card>
              <CardContent>
                <Typography variant="body1" component="h6">
                  Rutina
                </Typography>
                <Grid>
                  <List>
                    <ListItem>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" component="div">
                          1
                        </Typography>
                        <Chip
                          label="Pecho - Press Pecho Horizontal"
                          variant="filled"
                          color="default"
                          size="small"
                          style={{ color: Colors.primary }}
                          onClick={() => {}}
                        ></Chip>
                      </Grid>
                    </ListItem>
                    <Divider />
                    <ListItem>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="body1" component="div">
                          2
                        </Typography>
                        <Chip
                          label="PE 2 Vertical"
                          variant="filled"
                          color="default"
                          size="small"
                          style={{ color: Colors.primary }}
                          onClick={() => {}}
                        ></Chip>
                      </Grid>
                    </ListItem>
                  </List>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RoutineTwo;
