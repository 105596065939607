import { useState } from "react";
import { CardContent, Container, useTheme } from "@mui/material";
import { MyCard, MyLoading, MyTitle } from "../../components/Generic/Index";

const Home: React.FC = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();
  const logoPath = theme.palette.mode === 'dark' ? 'assets/login/logoMoveLight.svg' : 'assets/login/logoMoveDark.svg';
  return (

    <>

      {isActive && <MyLoading />}

      <Container fixed>

        <MyCard >
          <CardContent>
            <MyTitle
              title="¡Bienvenido a la plataforma de Move Wellness!"
              variant="h5"
              align="center"
              color="dark"
              gutterBottom
              noWrap
            />

            <MyTitle
              title="Estamos avanzando en las diferentes etapas de construcción para brindarte una herramienta intuitiva y poderosa 
                que mejore la eficiencia y organización de tu equipo. Estamos ansiosos por compartir contigo los avances y los beneficios 
                que nuestra plataforma aportará a Move Wellness."
              variant="body1"
              align="justify"
              color="dark"
            />
          </CardContent>

          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <img style={{ width: '15rem' }} alt="company logo" src={logoPath} />
            </div>
          </CardContent>
        </MyCard>

      </Container>
    </>
  );
};

export default Home;
