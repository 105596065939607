import { Tooltip } from "@mui/material";

interface MyTooltipProps {
  description: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  children: React.ReactElement;
}

const MyTooltip: React.FC<MyTooltipProps> = ({

  description = "lorem ipsum",
  placement = "right-start",
  children,

}) => {

  return (

    <Tooltip title={description} arrow placement={placement}>
      {children}
    </Tooltip>
  );
};

export default MyTooltip;
