import server from '../api/server';

export class UserInfoLocalService {
    static async getUser(token: string, id: any): Promise<any> {
        const headers = {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
        };

        try {
            const responseServer = await server.get(
              `staff/user/${id}`,
              {headers}
            );
            return responseServer.data.data;
        } catch (error: any) {
            throw new Error(error.response.status);
        }
    }
}
