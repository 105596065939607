import { useEffect, useState, useContext } from "react";
import { Container, Grid, Accordion, AccordionDetails, AccordionSummary, } from "@mui/material";
import { MyCard, MyLoading, MyTitle, MyTextField, MyButton, MySelect } from "../../components/Generic/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { MouldingConsultService } from "../../services/Nutrition/Moulding";
import { AppContext } from "../../data/state";
import { navigateWithScroll } from "../../functions/navigateUtils";
import { resulstDataNutrition } from "../../data/Nutrition/ResultsDictionary";
import moment from "moment";

const Results: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();

  const { state } = useContext(AppContext);

  const token = state.token;
  const [isActive, setIsActive] = useState(false);

  const [isReadOnly, setIsReadOnly] = useState(false);
  const [mouldingData, setMouldingData] = useState<any>();

  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState(0);
  const [consultId, setConsultId] = useState(0);
  const [userGender, setUserGender] = useState("");
  const [userType, setUserType] = useState("Normal");
  const userTypeOptions = [
    { label: "Seleccione una opción" },
    { label: "Normal" },
    { label: "Atleta" },
  ];
  const [userEvaluationDate, setUserEvaluationDate] = useState<string>("");
  const [userNumberSession, setUserNumberSession] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userHeight, setUserHeight] = useState("");
  const [userWeigth, setUserWeigth] = useState("");

  const formatDate = (date: any) => moment(date).format("YYYY-MM-DD");

  useEffect(() => {
    if (location.state && location.state.id) {
      setIsReadOnly(location.state.readOnly);
      (async () => {
        await requestMoulding(location.state.userId, location.state.id);
        await setUserId(location.state.userId);
        await setConsultId(location.state.id);
      })();
    }
  }, []);

  const requestMoulding = async (userId: any, consultId: any) => {
    setIsActive(true);
    try {
      const mouldingResult = await MouldingConsultService.mouldingConsult(token, userId, consultId);

      if(mouldingResult.information.gender === "HOMBRE") {
        delete mouldingResult.womenAthlete;
      } else {
        delete mouldingResult.menAthlete;
      }

      if(mouldingResult.information.consultType.id === 2) {
        delete mouldingResult.folds;
        delete mouldingResult.diameters;
      }
      setMouldingData(mouldingResult);
      setUserName(mouldingResult.information.name);
      setUserAge(mouldingResult.information.age);
      setUserWeigth(mouldingResult.information.weight);
      setUserHeight(mouldingResult.information.height);
      setUserGender(mouldingResult.information.gender);
      setUserEvaluationDate(formatDate(mouldingResult.information.consultDate));
      setUserNumberSession(mouldingResult.information.consultNum);
      setIsActive(false);
    } catch (error: any) {
      console.log(error);
      setIsActive(false);
    }
  };

  const goToCalculator = () => {
    navigateWithScroll(navigate, '/nutrition/calculator',
      { state: { consultId: consultId, userId: userId } });
  };

  const goBack = () => {
    navigateWithScroll(navigate, '/nutrition');
  };

  const checkTypeOf = (data: any, name: any) => {
    if (data === "string") {
      return data;
    }

    if (name === "icc") {
      return data.toFixed(2);
    }

    return data.toFixed(1);
  };

  const checkICERisk = (ICE: any) => {
    if (userGender == "HOMBRE") {
      if (ICE < 52.5) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    } else {
      if (ICE < 53.5) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    }
  };

  const checkICC_Risk = (ICC: any) => {
    if (userGender == "HOMBRE") {
      if (ICC < 0.9) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    } else {
      if (ICC < 0.75) {
        return "Bajo riesgo";
      } else {
        return "Alto riesgo";
      }
    }
  };

  const getAdornment = (data: any, key: any, value: any) => {
    if (data === "NA") {
      if (key === "ice") {
        return checkICERisk(value);
      } else if (key === "icc") {
        return checkICC_Risk(value);
      }
    } else {
      return data;
    }
    return "";
  };

  const getColor = (text: any) => {
    if (text === "Bajo riesgo") {
      return "primary";
    } else if (text === "Alto riesgo") {
      return "error";
    }

    return "light";
  };

  return (
    <>
      {isActive && <MyLoading />}
      <Container fixed>
        <MyCard >
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <MyTitle
                title={userName}
                variant="h5"
                align="left"
                color="primary"
              />
              <MyTitle
                title={"Número de socio: " + userId}
                variant="subtitle2"
                align="left"
                color="dark"
                gutterBottom
                noWrap
              />
            </Grid>
            <Grid item xs={8}>
              <MyTextField
                label="Fecha de evaluación"
                value={userEvaluationDate}
                type="date"
                id="userEvaluationDate"
                name="userEvaluationDate"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={4} >
              <MyTextField
                label="Numero de Sesión"
                value={userNumberSession}
                type="text"
                id="numSession"
                name="numSession"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="Edad"
                value={`${userAge} Años`}
                type="text"
                id="userAge"
                name="userAge"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="Peso"
                value={userWeigth}
                type="text"
                id="userWeigth"
                name="userWeigth"
                hasInputAdornment
                inputAdornmentText="kg"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="Estatura"
                value={userHeight}
                type="text"
                id="userHeight"
                name="userHeight"
                hasInputAdornment
                inputAdornmentText="cm"
                fullWidth
                readOnly
              />
            </Grid>
            <Grid item xs={3}>
              <MyTextField
                label="Sexo"
                value={userGender}
                type="text"
                id="userGender"
                name="userGender"
                fullWidth
                readOnly
              />
            </Grid>
          </Grid>
        </MyCard>
        <MyCard >
          <Grid container spacing={2} marginBottom={1} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={9}>
              <MyTitle
                title="Medidas antropométricas"
                variant="h5"
                align="left"
                color="primary"
                noWrap
              />
            </Grid>
            <Grid item xs={3}>
            {
              !isReadOnly ?
                <MyButton
                  title="Ir a la calculadora"
                  color="primary"
                  handleClick={goToCalculator}
                />
              :
                <MyButton
                  title="Regresar al buscador"
                  color="primary"
                  handleClick={goBack}
                />
            }
            </Grid>
            <Grid item xs={12}>
              {
                mouldingData && Object.entries(mouldingData as any).map(([key, value]) => (
                  key !== "information" && resulstDataNutrition.has(key) && value !== null ? (
                    <Accordion key={key} >
                      <AccordionSummary>
                        <MyTitle
                          title={resulstDataNutrition.get(key).get("name")}
                          variant="subtitle1"
                          align="left"
                          color="dark"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid key={key} container spacing={2} marginBottom={1} direction="row" justifyContent="flex-start" alignItems="center">
                          {
                            Object.entries(value as any).map(([k, val], index) => (
                              val !== null && resulstDataNutrition.get(key).get(k) !== undefined && (
                                <Grid item xs={4} key={k} >
                                  <MyTextField
                                    label={resulstDataNutrition.get(key).get(k).part}
                                    value={val ? checkTypeOf(val, k) : ""}
                                    type="text"
                                    id={k}
                                    key={k}
                                    name={k}
                                    hasInputAdornment
                                    inputAdornmentText={getAdornment(resulstDataNutrition.get(key).get(k).unity, k, val)}
                                    fullWidth
                                    readOnly
                                  />
                                </Grid>
                              )
                            ))
                          }
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ) : (null)
                ))
              }
            </Grid>
          </Grid>
        </MyCard>
        <div className="buttons-navigation-position">
          <div>
          {
            !isReadOnly ?
              <MyButton
                title="Ir a la calculadora"
                color="primary"
                handleClick={goToCalculator}
              />
            :
              <MyButton
                title="Regresar al buscador"
                color="primary"
                handleClick={goBack}
              />
          }
          </div>
        </div>
      </Container>
    </>
  );
};

export default Results;
